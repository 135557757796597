/**
 * Store caller id module.
 *
 * @module store/senders-ids/caller-id/module
 * @author Dilan Useche <dilan8810@gmail.com>
 * */

import state from './caller-id.state';
import mutations from './caller-id.mutations';
import actions from './caller-id.actions';

/**
 * export default
 *  @type {VuexModule}
 * */
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
