export default {
  SET_SELECTED_CATEGORY(state, val) {
    state.selectedCategory = val;
  },
  SET_SELECTED_SECTION(state, val) {
    state.selectedSection = val;
  },
  SET_SELECTED_QUESTION(state, val) {
    state.selectedQuestion = val;
  },
};
