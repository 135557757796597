import authService from '@/api/modules/auth.service';
import userService from '@/api/modules/user.service';
import tenantService from '@/api/modules/tenant.service';
import { uploadFileS3, deleteFileS3, getPreSignedUrlS3 } from '@/aws';
import { getExtFromFileType } from '@/util';

/**
 * Actions of the store auth module.
 *
 * Use the auth service to connect to the api. See {@link module:api/auth}
 * @module store/auth/actions
 * @author Dilan Useche <dilan8810@gmail.com>
 * */
export default {
  /**
   * Make login by basic or google authentication.
   *
   * Use signIn and signInGoogle auth services to make the login and get tokens.
   * See {@link module:api/auth~signIn}
   * @param {Function} commit - vuex store commit function
   * @param {Function} dispatch - vuex store dispatch function
   * @param {string} email - email to login
   * @param {string} password - password to login
   * @return {Object | any}
   */
  async login({ commit, dispatch }, { email, password }) {
    const resp = await authService.signIn({ email, password });

    if (resp.data.twoFactorAuthenticationActivate) {
      return resp;
    }

    await dispatch('setTokens', {
      token: resp.data.token,
      refreshToken: resp.data.refreshToken,
    });

    commit('SET_AUTH_USER', resp.data.user);
    commit('SET_AUTH_USER_IS_LOGGED_IN', true);

    await dispatch('refreshToken');

    return resp;
  },

  /**
   * Make login by second step auth
   *
   * @param {Function} commit - vuex store commit function
   * @param {Function} dispatch - vuex store dispatch function
   * @param {string} code - 2FA code
   * @param {string} token - first step validation token
   * @return {Object | any}
   */
  async loginSecondStep({ commit, dispatch }, { code, token }) {
    const resp = await authService.signInSecondStep({
      code,
      tokenSecondStep: token,
    });

    await dispatch('setTokens', {
      token: resp.data.token,
      refreshToken: resp.data.refreshToken,
    });

    commit('SET_AUTH_USER', resp.data.user);
    commit('SET_AUTH_USER_IS_LOGGED_IN', true);

    await dispatch('refreshToken');

    return resp;
  },

  /**
   * Make login by basic or google authentication.
   *
   * Use signIn and signInGoogle auth services to make the login and get tokens.
   * See {@link module:api/auth~signIn}
   * @param {Function} commit - vuex store commit function
   * @param {Function} dispatch - vuex store dispatch function
   * @param {string} token - access token
   * @param {string} refreshToken - refresh token
   * @return {void}
   */
  async verifyToken({ commit, dispatch }, { token, refreshToken }) {
    commit('SET_AUTH_TOKEN', token);
    const resp = await authService.verifyToken();
    commit('SET_AUTH_REFRESH_TOKEN', refreshToken);
    commit('SET_AUTH_USER', resp.data.user);
    commit('SET_AUTH_USER_IS_LOGGED_IN', true);

    await dispatch('refreshToken');
  },

  /**
   * Make sign up.
   *
   * Use signUp auth services to create the new user and get the tokens.
   * See {@link module:api/auth~signUp}
   * @param {Object} user - user info
   * @return {any}
   */
  // eslint-disable-next-line no-empty-pattern
  async signUp({}, user) {
    const resp = await authService.signUp(user);
    return resp;
  },

  /**
   * Complete the sign up from an invited user.
   *
   * Use invitedSignUp auth services to create the new user and get the tokens.
   * See {@link module:api/auth~invitedSignUp}
   * @param {Function} commit - vuex store commit function
   * @param {Function} dispatch - vuex store dispatch function
   * @param {string} email - email to validate the sing up
   * @param {string} securityToken - security token to validate the sing up
   * @param {Object} user - user info
   * @return {void}
   */
  async invitedSignUp({ commit, dispatch }, { email, securityToken, user }) {
    const resp = await authService.invitedSignUp({ email, securityToken, user });

    await dispatch('setTokens', {
      token: resp.data.token,
      refreshToken: resp.data.refreshToken,
    });

    commit('SET_AUTH_USER', resp.data.user);
  },

  /**
   * Request an recovery password email.
   *
   * Use recoverPassword auth services to send the recovery email.
   * See {@link module:api/auth~recoverPassword}
   * @param {Function} commit - vuex store commit function
   * @param {string} email - email address to send recovery email
   * @return {void}
   */
  // eslint-disable-next-line no-unused-vars
  async recoverPassword({ commit }, email) {
    await authService.recoverPassword(email);
  },

  /**
   * Make the change password for an user.
   *
   * Use changePassword auth services to reset the password.
   * See {@link module:api/auth~changePassword}
   * @param {Function} commit - vuex store commit function
   * @param {string} jwtTokenRecoverPassword - security jwt token to validate the sing up
   * @param {string} password - new password
   * @return {void}
   */
  // eslint-disable-next-line no-unused-vars
  async changePassword({ commit }, { jwtTokenRecoverPassword, password }) {
    await authService.changePassword({ jwtTokenRecoverPassword, password });
  },

  /**
   * Set the auth tokens and its info
   *
   * @param {Function} commit - vuex store commit function
   * @param {string} token - access token
   * @param {string} refreshToken - refresh token
   * @return {void}
   */
  setTokens({ commit }, {
    token, refreshToken,
  }) {
    commit('SET_AUTH_TOKEN', token);
    commit('SET_AUTH_REFRESH_TOKEN', refreshToken);
  },

  /**
   * Refresh auth tokens.
   *
   * Use refreshTokens auth services to refresh the tokens.
   * See {@link module:api/auth~refreshTokens}
   * @param {Function} commit - vuex store commit function
   * @param {Function} dispatch - vuex store dispatch function
   * @return {void}
   */
  async refreshToken({ state, commit }) {
    if (state.isRefreshingToken) {
      return state.refreshingTokenCall;
    }

    commit('SET_AUTH_IS_REFRESHING_TOKEN', true);
    const refreshingCall = authService.refreshTokens().then((resp) => {
      commit('SET_AUTH_TOKEN', resp.data.token);
      commit('SET_AUTH_REFRESH_TOKEN', resp.data.refreshToken);
      commit('SET_AUTH_IS_REFRESHING_TOKEN', false);
      commit('SET_AUTH_REFRESHING_TOKEN_CALL', null);
      return Promise.resolve(true);
    });

    commit('SET_AUTH_REFRESHING_TOKEN_CALL', refreshingCall);
    return refreshingCall;
  },

  /**
   * Make logout and delete the save credentials tokens.
   *
   * Use logout auth services to make the logout.
   * See {@link module:api/auth~logout}
   * @param {Function} commit - vuex store commit function
   * @return {void}
   */
  async logout({ commit, dispatch }) {
    try {
      await authService.logout();
    } catch (e) {
      console.log(e);
    }

    dispatch('resetBreadcrumb', [], { root: true });
    commit('DELETE_AUTH');
  },

  /**
   * fetch tenant of user
   *
   * @param {Function} commit - vuex store commit function
   * @return {Promise.<Object | any>}
   */
  async fetchTenantOfUser({ commit }) {
    const resp = await tenantService.getTenantFromUser();

    commit('PARTIAL_UPDATE_AUTH_USER', { tenant: resp.data });
    return resp.data;
  },

  /**
   * fetch the user flags and update the auth user
   *
   * Use getFlags user service to get the flags
   * @param {Function} commit - vuex store commit function
   * @return {Promise.<Object | any>}
   */
  async fetchUserFlags({ commit }) {
    const resp = await userService.getFlags();
    const flags = {
      flags: {
        ...resp.data,
      },
    };

    commit('PARTIAL_UPDATE_AUTH_USER', flags);

    return resp.data;
  },

  /**
   * fetch the balance information and update the auth user
   *
   * Use getBalanceInformation user service to get the balance information.
   * See {@link module:api/modules/user~getBalanceInformation}.
   * @param {Function} commit - vuex store commit function
   * @return {Promise.<Object | any>}
   */
  async fetchBalanceInformation({ commit }) {
    const resp = await tenantService.getBalanceInformation();

    commit('PARTIAL_UPDATE_AUTH_USER_TENANT', resp.data);

    return resp.data;
  },

  /**
   * Update the profile of auth user.
   *
   * Use updateProfile user service to update the user profile.
   * See {@link module:api/modules/user~updateProfile}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} payload - user profile payload
   * @return {Promise.<Object | any>} user profile updated
   */
  async updateProfile({ commit }, payload) {
    const resp = await userService.updateProfile(payload);

    commit('PARTIAL_UPDATE_AUTH_USER', resp.data);

    return resp.data;
  },

  /**
   * Verify a new profile email
   *
   * @param {Function} commit - vuex store commit function
   * @param {string} email - user profile email
   * @param {string} code - code to validate
   * @param {string} token - token to validate
   * @return {Promise.<Object | any>} user profile updated
   */
  async verifyProfileEmail({ commit }, { email, code, token }) {
    const resp = await userService.verifyProfileEmail({
      email,
      code,
      token,
    });

    commit('PARTIAL_UPDATE_AUTH_USER', {
      email: resp.data,
    });

    return resp.data;
  },

  /**
   * Update user flag associated with welcome tour.
   *
   * @param {Function} commit - vuex store commit function
   * @return {Promise.<Object | any>}
   */
  async skippedOrFinishedWelcomeTour({ commit }) {
    const payload = {
      welcomeTourSkipped: true,
    };

    const resp = await userService.updateFlags(payload);
    commit('UPDATE_AUTH_USER_FLAGS', payload);
    return resp.data;
  },

  /**
   * Update user flag associated with sms campaign get started modal.
   *
   * @param {Function} commit - vuex store commit function
   * @return {Promise.<Object | any>}
   */
  async skippedSMSCampaignGetStartedModal({ commit }) {
    const payload = {
      smsCampaignGetStartedModalSkipped: true,
    };

    const resp = await userService.updateFlags(payload);
    commit('UPDATE_AUTH_USER_FLAGS', payload);
    return resp.data;
  },

  /**
   * Update user flags associated with terms & conditions.
   *
   * @param {Function} commit - vuex store commit function
   * @param {Object} payload - user profile payload
   * @return {Promise.<Object | any>}
   */
  async acceptedTermsAndConditions({ commit }, payload) {
    const resp = await userService.updateFlags(payload);
    commit('UPDATE_AUTH_USER_FLAGS', payload);
    return resp.data;
  },

  /**
   * Update user flag associated with two factor authentication
   *
   * @param {Function} commit - vuex store commit function
   * @param {string} code - six-digits code for enable "FA
   * @return {Promise.<Object | any>}
   */
  async enableTwoFactorAuthentication({ commit }, code) {
    await userService.enableTwoFactorAuthentication(code);
    commit('UPDATE_AUTH_USER_FLAGS', {
      twoFactorAuthentication: true,
    });
    return true;
  },

  /**
   * Update user flag associated with two-factor authentication
   *
   * @param {Function} commit - vuex store commit function
   * @param {string} payload - payload to validate the action
   * @return {Promise.<Object | any>}
   */
  async disableTwoFactorAuthentication({ commit }, payload) {
    await userService.disableTwoFactorAuthentication(payload);
    commit('UPDATE_AUTH_USER_FLAGS', {
      twoFactorAuthentication: false,
    });
    return true;
  },

  /**
   * Update user flag associated with two-factor authentication
   *
   * @param {Function} commit - vuex store commit function
   * @param {string} code - code to validate the action
   * @return {Promise.<Object | any>}
   */
  async disableTwoFactorAuthenticationByEmailCode({ commit }, code) {
    await userService.disableTwoFactorAuthenticationByEmailCode(code);
    commit('UPDATE_AUTH_USER_FLAGS', {
      twoFactorAuthentication: false,
    });
    return true;
  },

  /**
   * Update password of user.
   *
   * Use updatePassword user service to update the user password.
   * See {@link module:api/modules/user~updatePassword}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} payload - user password payload
   * @return {Promise.<void>}
   */
  async updatePassword({ commit }, payload) {
    await userService.updatePassword(payload);
    commit('UPDATE_AUTH_USER_FLAGS', {
      hasPassword: true,
    });
  },

  /**
   * Update avatar info of user.
   *
   * Use updateAvatar user service to update the user password.
   * See {@link module:api/modules/user~updatePassword}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} getters - vuex store getters
   * @param {Blob} file - user password payload
   * @return {Promise.<void>}
   */
  async updateAvatar({ commit, getters }, file) {
    if (file && file.type) {
      const ext = getExtFromFileType(file.type);
      const bucketPath = `${getters.basePathUserToUploadResources}/avatar.${ext}`;

      const respS3 = await uploadFileS3(file, bucketPath);
      const preSignedUrl = await getPreSignedUrlS3(respS3.Key);

      const payload = {
        url: preSignedUrl,
        bucketPath: respS3.Key,
      };

      await userService.updateAvatar({
        url: preSignedUrl,
        bucketPath: respS3.Key,
      });

      commit('PARTIAL_UPDATE_AUTH_USER', {
        avatar: payload,
      });
    }
  },

  /**
   * remove avatar info of user.
   *
   * Use removeAvatar user service to remove the user avatar.
   * See {@link module:api/modules/user~removeAvatar}.
   * @param {Object} getters - vuex store getters object
   * @param {Function} commit - vuex store commit function
   * @return {Promise.<void>}
   */
  async removeAvatar({ getters, commit }) {
    await deleteFileS3(getters.avatarBucketPath);
    await userService.removeAvatar();

    commit('PARTIAL_UPDATE_AUTH_USER', {
      avatar: null,
    });
  },

  /**
   * disconnect google account form auth user
   *
   * Use disconnectToGoogle user service to disconnect the google account
   * @param {Function} state - vuex store commit function
   * @param {Function} commit - vuex store commit function
   * @return {Promise.<void>}
   */
  async disconnectToGoogle({ state, commit }) {
    await userService.disconnectToGoogle();

    commit('PARTIAL_UPDATE_AUTH_USER', {
      ...state.user,
      googleEmail: null,
    });
  },

  /**
   * update the tenant account details
   *
   * @param {Function} commit - vuex store commit function
   * @param {Function} dispatch - vuex store dispatch function
   * @param {Object} payload - account details settings
   * @return {Promise.<Object | any>}
   */
  async updateTenantAccountDetailsSettings({ commit, dispatch }, payload) {
    const resp = await tenantService.updateAccountSettingsDetails(payload);
    commit('UPDATE_AUTH_USER_TENANT_ACCOUNT_SETTINGS_REVIEWS', resp.data);
    await dispatch('fetchTenantOfUser');
    return resp.data;
  },

  /**
   * update the tenant account reviews
   *
   * @param {Function} commit - vuex store commit function
   * @param {Object} payload - review settings
   * @return {Promise.<Object | any>}
   */
  async updateTenantAccountReviewSettings({ commit }, payload) {
    const resp = await tenantService.updateAccountSettingsReviews(payload);
    commit('UPDATE_AUTH_USER_TENANT_ACCOUNT_SETTINGS_REVIEWS', resp.data);
    return resp.data;
  },

  /**
   * update one marker on auth user
   *
   * @param {Object} getters - vuex store getters
   * @param {Function} commit - vuex store commit function
   * @param {Object} marker - marker to update
   * @return {Promise.<Object | any>}
   */
  async updateUserMarker({ getters, commit }, marker) {
    const { markers } = getters;

    if (marker.marked) {
      const index = markers.indexOf(marker.slug);

      if (index !== -1) {
        markers.splice(index, 1);
      }
    } else {
      markers.push(marker.slug);
    }

    const resp = await userService.updateMarkers(markers);
    commit('UPDATE_AUTH_USER_MARKERS', markers);
    return resp.data;
  },

  /**
   * update markers of auth user
   *
   * @param {Function} commit - vuex store commit function
   * @param {string[]} markers - markers to update
   * @return {Promise.<Object | any>}
   */
  async updateUserMarkers({ commit }, markers) {
    const resp = await userService.updateMarkers(markers);
    commit('UPDATE_AUTH_USER_MARKERS', markers);
    return resp.data;
  },
};
