import attributeService from '@/api/modules/attribute.service';

/**
 * Actions of the store attribute module.
 *
 * Use the attribute service to connect to the api.
 * See {@link module:api/modules/audiences/attribute}
 * @module store/audiences/attribute/actions
 * @author Dilan Useche <dilan8810@gmail.com>
 * */
export default {

  /**
   * Get all attributes in the organization that the requesting user has access to
   *
   * Use getAll attribute service to fetch the attributes.
   * See {@link module:api/modules/audiences/attribute~getAll}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} params - query params
   * @return {Promise.<Array.<Object> | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async fetchAllAttributes({ commit }, params) {
    const resp = await attributeService.getAll(params);
    return resp.data;
  },

  /**
   * Get one attribute by id
   *
   * Use getOne attribute service to fetch the attribute
   * See {@link module:api/modules/audiences/attribute~getOne}.
   * @param {Object} getters - vuex getters of attribute module
   * @param {Object} state - vuex state of attribute module
   * @param {string} attributeId - id of attribute to get
   * @return {Promise.<Object>} attribute
   */
  // eslint-disable-next-line no-unused-vars
  async fetchAttribute({ getters }, attributeId) {
    const resp = await attributeService.getOne({ id: attributeId });
    return resp.data;
  },

  /**
   * Create a new attribute.
   *
   * Use create attribute service to create the attribute.
   * See {@link module:api/modules/audiences/attribute~create}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} rootState - vuex store root state
   * @param {Object} payload - attribute to create
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async addAttribute({ commit }, payload) {
    const resp = await attributeService.create(payload);
    return resp.data;
  },

  /**
   * Export many attributes ids from file.
   *
   * Use exportFile attribute service to export the attributes ids.
   * See {@link module:api/modules/audiences/attribute~exportFile}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} rootState - vuex store root state
   * @param {Object} exportInfo - attribute export info
   * @return {Promise<string | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async exportFile({ commit }, exportInfo) {
    const resp = await attributeService.exportFile(exportInfo);
    return resp.data;
  },

  /**
   * Update and commit one attribute.
   *
   * Use edit attribute service to update the attribute.
   * See {@link module:api/modules/audiences/attribute~edit}.
   * @param {Function} commit - vuex store commit function
   * @param {string} id - id of attribute to update
   * @param {Object} attribute - attribute to update
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async editAttribute({ commit }, { id, ...attribute }) {
    const resp = await attributeService.edit({
      id,
      payload: attribute,
    });
    return resp.data;
  },

  /**
   * Add one item to category attribute
   *
   * @param {Function} commit - vuex store commit function
   * @param {Object} rootState - vuex store root state
   * @param {string} attributeId - id of attribute to add item
   * @param {Object} payload - attribute to create
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async addItemToAttribute({ commit }, { attributeId, payload }) {
    const resp = await attributeService.addItemToAttribute({
      id: attributeId,
      payload,
    });
    return resp.data;
  },

  /**
   * Delete one attribute by id
   *
   * Use delete attribute service to delete the attribute.
   * See {@link module:api/modules/audiences/attribute~delete}.
   * @param {Function} commit - vuex store commit function
   * @param {string} attributeId - id of attribute to delete
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async removeAttribute({ commit }, attributeId) {
    const resp = await attributeService.delete(attributeId);
    return resp.data;
  },

  /**
   * Delete many attributes ids by id
   *
   * Use deleteMany attribute service to delete the attributes ids.
   * See {@link module:api/modules/audiences/attribute~deleteMany}.
   * @param {Function} commit - vuex store commit function
   * @param {Array.<Object> | null} data - attributes ids to delete
   * @param {Object} filters - filters to search attributes ids to delete
   * @return {Promise.<any>}
   */
  // eslint-disable-next-line no-unused-vars
  async removeAttributes({ commit }, { data = null, filters = {} }) {
    const resp = await attributeService.deleteMany({
      data: data ? data.map((t) => t.id) : null,
      filters,
    });

    return resp.data;
  },
};
