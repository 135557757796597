import appServiceService from '@/api/modules/app-service.service';

/**
 * Actions of the store app service module.
 *
 * Use the appService service to connect to the api.
 * See {@link module:api/modules/app-service}
 * @module store/app-service/actions
 * @author Dilan Useche <dilan8810@gmail.com>
 * */
export default {
  /**
   * Get all app services
   *
   * Use getAll audience service to fetch the audiences.
   * See {@link module:api/modules/app-service~getAll}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} params - query params
   * @return {Promise.<Array.<Object> | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async fetchAllAppServices({ commit }, params) {
    const resp = await appServiceService.getAll(params);
    return resp.data;
  },

  /**
   * Get one app service by id
   *
   * Use getOne appService service to fetch the app service
   * See {@link module:api/modules/app-service~getOne}.
   * @param {Object} getters - vuex getters of module
   * @param {Object} state - vuex state of module
   * @param {string} appServiceId - id of app service to get
   * @return {Promise<Object>} audience
   */
  // eslint-disable-next-line no-unused-vars
  async fetchAppService({ getters }, appServiceId) {
    const resp = await appServiceService.getOne({ id: appServiceId });
    return resp.data;
  },

  /**
   * add new app service.
   *
   * Use create appService service to create the app service.
   * See {@link module:api/modules/app-service~create}.
   * @param {Function} commit - vuex store commit function
   * @param {Function} dispatch - vuex store dispatch function
   * @param {Object} appService - appService to create
   * @return {Promise.<Object | any>} app service created
   */
  // eslint-disable-next-line no-unused-vars
  async addAppService({ commit }, appService) {
    const resp = await appServiceService.create(appService);
    return resp.data;
  },

  /**
   * Export app services to file.
   *
   * Use exportFile appService service to export the app services.
   * See {@link module:api/modules/app-service~exportFile}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} exportInfo - app service export info
   * @return {Promise<string | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async exportFile({ commit }, exportInfo) {
    const resp = await appServiceService.exportFile(exportInfo);
    return resp.data;
  },

  /**
   * Update an app service.
   *
   * Use edit appService service to update the app service.
   * See {@link module:api/modules/app-service~edit}.
   * @param {Function} commit - vuex store commit function
   * @param {string} id - id of app service to update
   * @param {Object} appService - app service to update
   * @return {Promise.<Object | any>} app service updated
   */
  // eslint-disable-next-line no-unused-vars
  async editAppService({ commit }, { id, ...appService }) {
    const resp = await appServiceService.edit({
      id,
      payload: appService,
    });
    return resp.data;
  },

  /**
   * Delete one app service by id
   *
   * Use delete appService service to delete the app service.
   * See {@link module:api/modules/app-service~delete}.
   * @param {Function} commit - vuex store commit function
   * @param {string} appServiceId - id of app service to delete
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async removeAppService({ commit }, appServiceId) {
    const resp = await appServiceService.delete(appServiceId);
    return resp.data;
  },

  /**
   * Delete many app services by id
   *
   * Use deleteMany appService service to delete the app services.
   * See {@link module:api/modules/app-service~deleteMany}.
   * @param {Function} commit - vuex store commit function
   * @param {Array.<Object>} data - app services to delete
   * @param {Object} filters - filters to search tags to delete
   * @return {Promise.<any>}
   */
  // eslint-disable-next-line no-unused-vars
  async removeAppServices({ commit }, { data, filters = {} }) {
    const resp = await appServiceService.deleteMany({
      data: data ? data.map((t) => t.id) : null,
      filters,
    });

    return resp.data;
  },
};
