import BaseService from '@/api/base.service';
import configApi from '@/api/config';
import grapePerksAppService from '@/api';
import enums from "@/enums";

/**
 * notification service api
 * @class NotificationService
 */
class NotificationService extends BaseService {
  constructor() {
    super({ baseUrl: configApi.baseRoutesUrls.notifications });
  }

  /**
   * Get unread notifications
   * @function getUnread
   * @return {Promise.<GrapePerksApiResponse | any>} unread notifications
   */
  getUnseen = () => grapePerksAppService.get(`${this.baseUrl}/unseen`)
    .then((resp) => resp.data);

  /**
   * Get all notifications grouped by day
   * @function getByDay
   * @return {Promise.<GrapePerksApiResponse | any>} notifications
   */
  getAllByDay= ({
    sortBy = [],
    filters = {},
    filtersMatch = enums.AppFilter.FilterMathType.ALL,
    skip = 0,
    limit = 0,
    ...params
  }) => grapePerksAppService.get(`${this.baseUrl}/by-day`, {
    params: {
      ...params,
      sortBy,
      filters,
      filtersMatch,
      skip,
      limit,
    },
  })
    .then((resp) => resp.data);

  /**
   * Get unread notifications
   * @function getUnread
   * @return {Promise.<GrapePerksApiResponse | any>} unread notifications
   */
  updateAsViewed = (payload) => grapePerksAppService.put(`${this.baseUrl}/viewed`, payload)
    .then((resp) => resp.data);
}

const singletonInstance = new NotificationService();

/**
 * notification service api instance
 * @exports api/modules/notification
 * */
export default singletonInstance;
