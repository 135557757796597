import configApi from '@/api/config';
import BaseService from '@/api/base.service';
import grapePerksAppService from "@/api";

/**
 * attribute service api
 * @class AttributeService
 */
class AttributeService extends BaseService {
  constructor() {
    super({ baseUrl: configApi.baseRoutesUrls.attributes });
  }

  /**
   * add one item to category attribute
   * @function addItemToAttribute
   * @param {string} id - id of attribute to update
   * @param {Object} payload - payload of item to add
   * @return {Promise.<GrapePerksApiResponse | any>} attribute updated
   */
  addItemToAttribute= ({ id, payload }) => grapePerksAppService.put(
    `${this.baseUrl}/add-item-attribute/${id}`, payload,
  ).then((resp) => resp.data);
}

const singletonInstance = new AttributeService();

/**
 * attribute service api instance
 * @exports api/modules/audiences/attribute
 * */
export default singletonInstance;
