import configApi from '@/api/config';
import BaseService from '@/api/base.service';

/**
 * tag service api
 * @class TagService
 */
class TagService extends BaseService {
  constructor() {
    super({ baseUrl: configApi.baseRoutesUrls.tags });
  }
}

const singletonInstance = new TagService();

/**
 * tag service api instance
 * @exports api/modules/audiences/tag
 * */
export default singletonInstance;
