/**
 * Mutations of the store caller id module.
 *
 * @module store/senders-ids/caller-id/mutations
 * @author Dilan Useche <dilan8810@gmail.com>
 * */
export default {
  /**
   * set last created Caller ID
   *
   * @param {Object} state - vuex caller-id state
   * @param {Object} callerId - Caller ID created
   * @return {void}
   */
  SET_CALLER_ID_LAST_CREATED(state, callerId) {
    state.lastCreated = callerId;
  },
};
