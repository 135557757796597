import enums from '@/enums';
import { v4 as uuidv4 } from 'uuid';

/**
 * Getters of the store auth module.
 *
 * @module store/auth/getters
 * @author Dilan Useche <dilan8810@gmail.com>
 * */
export default {
  /**
   * Indicate if the user is authenticated or no
   *
   * @param {Object} state - state of module
   * @return {boolean} true is user logged in, otherwise false
   */
  isUserLoggedIn(state) {
    return state.isUserLoggedIn;
  },

  /**
   * Indicate if the operation of refresh token is happened
   *
   * @param {Object} state - state of module
   * @return {boolean} true if the token is being refreshed, otherwise false
   */
  isRefreshingToken(state) {
    return state.isRefreshingToken;
  },

  /**
   * Return the auth user id
   *
   * @param {Object} state - state of module
   * @return {string} id of the auth user
   */
  id(state) {
    return state.user && state.user.id ? state.user.id : null;
  },

  /**
   * Return the google email of the auth user if exist
   *
   * @param {Object} state - state of module
   * @return {string} google email of the auth user if exist, otherwise null
   */
  googleEmail(state) {
    return state.user && state.user.googleEmail ? state.user.googleEmail : null;
  },

  /**
   * Indicate if the auth user has set up the google email
   *
   * @param {Object} state - state of module
   * @return {boolean} true if user has a google email, otherwise false
   */
  hasGoogleEmail(state, getters) {
    return !!getters.googleEmail;
  },

  /**
   * Return the role of the auth user if exist
   *
   * @param {Object} state - state of module
   * @return {string} role of the auth user if exist, otherwise null
   */
  role(state) {
    return state.user && state.user.role ? state.user.role : null;
  },

  /**
   * Return the permissions of the auth user if exist
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {Array.<Object>} permissions of the auth user if exist, otherwise []
   */
  permissions(state, getters) {
    return [];
  },

  /**
   * Return the role prefix name of the auth user if exist
   *
   * @param {Object} state - state of module
   * @return {string} role prefix name of the auth user if exist, otherwise null
   */
  rolePrefix(state) {
    return '';
  },

  /**
   * Return the role suffix name of the auth user if exist
   *
   * @param {Object} state - state of module
   * @return {string} role suffix name of the auth user if exist, otherwise null
   */
  roleSuffix(state) {
    return '';
  },

  /**
   * Indicate if the auth user is an admin
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {boolean} true if auth user is an admin, false if his is not or auth user does not exit
   */
  roleIsAdmin(state, getters) {
    return getters.role === enums.Auth.Role.ADMIN;
  },

  /**
   * Indicate if the auth user is an merchant
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {boolean} true if auth user is an merchant,
   * false if his is not or auth user does not exit
   */
  roleIsMerchant(state, getters) {
    return getters.role === enums.Auth.Role.MERCHANT;
  },

  /**
   * Indicate if the auth user is an owner(admin or merchant)
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {boolean} true if auth user is an owner, otherwise false
   */
  isOwner(state, getters) {
    return false;
  },

  /**
   * Indicate if the auth user is an admin(admin or merchant)
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {boolean} true if auth user is an admin, otherwise false
   */
  isAdmin(state, getters) {
    return false;
  },

  /**
   * return the id of auth user
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {number|null} id of auth user if exist, otherwise null
   */
  userId(state, getters) {
    if (getters.isUserLoggedIn && state.user && state.user.id) {
      return state.user.id;
    }

    return null;
  },

  /**
   * return the markers of user
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {string[]} id of auth user if exist, otherwise null
   */
  markers(state, getters) {
    if (getters.isUserLoggedIn && state.user && state.user.markers) {
      return state.user.markers || [];
    }

    return [];
  },

  /**
   * Indicate if the user has the key flags or no
   *
   * @param {Object} state - state of module
   * @return {Object} true if user has flags, otherwise false
   */
  userHasFlags(state) {
    return !!(state.user && state.user.flags);
  },

  /**
   * return the user flags
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {Object} tenant flags
   */
  userFlags(state, getters) {
    return getters.userHasFlags
      ? state.user.flags || {}
      : {};
  },

  /**
   * get flag value for user
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {boolean | null} value of requested flag or null
   */
  getUserFlag: (state, getters) => (flagName) => (
    getters.userHasFlags
    && getters.userFlags[flagName] !== undefined
    && getters.userFlags[flagName] !== null
      ? getters.userFlags[flagName] || false
      : null
  ),

  /**
   * return the tenant of auth user
   *
   * @param {Object} state - state of module
   * @return {Object | null} tenant of user
   */
  tenant(state) {
    if (state.user && state.user.tenant) {
      return state.user.tenant;
    }

    return null;
  },

  /**
   * return the tenantId of auth user
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {string | null} tenantId of auth user
   */
  tenantId(state, getters) {
    if (getters.tenant && getters.tenant.id) {
      return getters.tenant.id;
    }

    return null;
  },

  /**
   * Indicate if the tenant of user has the key flags or no
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {Object} true if tenant of user has flags, otherwise false
   */
  tenantHasFlags(state, getters) {
    return !!(getters.tenant && getters.tenant.flags);
  },

  /**
   * return the tenant flags
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {Object} tenant flags
   */
  tenantFlags(state, getters) {
    return getters.tenantHasFlags
      ? state.user.tenant.flags || {}
      : {};
  },

  /**
   * get flag value for tenant
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @param {string} flagName - flag to get
   * @return {boolean | null} requested flag value or null
   */
  getTenantFlag: (state, getters) => (flagName) => (
    getters.tenantHasFlags
    && getters.tenantFlags[flagName] !== undefined
    && getters.tenantFlags[flagName] !== null
      ? getters.tenantFlags[flagName] || false
      : null
  ),

  /**
   * get tenant account settings reviews
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {Object | null} Tenant account review settings or null
   */
  tenantAccountSettingsReviews(state, getters) {
    if (getters.tenant
      && getters.tenant.accountSettings
      && getters.tenant.accountSettings.review) {
      return getters.tenant.accountSettings.review;
    }

    return null;
  },

  /**
   * get tenant google review link
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {string} tenant google review link
   */
  tenantGoogleReviewLink(state, getters) {
    const reviewSettings = getters.tenantAccountSettingsReviews;
    return reviewSettings ? reviewSettings.urlReviewGoogle : '';
  },

  /**
   * get tenant short review link
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {string} tenant short review link
   */
  tenantShortReviewLink(state, getters) {
    const reviewSettings = getters.tenantAccountSettingsReviews;
    return reviewSettings ? reviewSettings.urlReviewShort : '';
  },

  /**
   * get tenant account details
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {Object} account details
   */
  tenantAccountDetails(state, getters) {
    if (getters.tenant
      && getters.tenant.accountSettings
      && getters.tenant.accountSettings.details) {
      return getters.tenant.accountSettings.details;
    }

    return null;
  },

  /**
   * get tenant account country  details
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {string} account country detail
   */
  tenantAccountDetailsCountry(state, getters) {
    if (getters.tenantAccountDetails) {
      return getters.tenantAccountDetails.country || '';
    }

    return '';
  },

  /**
   * get tenant account timezone detail
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {string} account timezone detail
   */
  tenantAccountDetailsTimezone(state, getters) {
    if (getters.tenantAccountDetails) {
      return getters.tenantAccountDetails.timeZone || '';
    }

    return '';
  },

  /**
   * get tenant app service items
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {Array<Object>} app service items
   */
  tenantAppServiceItems(state, getters) {
    if (getters.tenant
      && getters.tenant.appServiceItems) {
      return getters.tenant.appServiceItems;
    }

    return [];
  },

  /**
   * get tenant app service item by data attribute
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {Object || null} app service item
   */
  getTenantAppServiceItemByDataAttr: (state, getters) => ({
    appServiceId, dataAttrKey, dataAttrValue,
  }) => getters.tenantAppServiceItems.find(
    (item) => item.appServiceId === appServiceId
      && item.data[dataAttrKey] === dataAttrValue,
  ) || null,

  /**
   * get tenant account business name
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {string} Tenant business name or empty string
   */
  tenantAccountBusinessName(state, getters) {
    return getters.tenantAccountDetails ? getters.tenantAccountDetails.name || '' : '';
  },

  /**
   * Return the base path to upload resource based on tenant id
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {string} base path
   */
  basePathTenantToUploadResources(state, getters) {
    if (getters.isUserLoggedIn) {
      return getters.tenantId;
    }

    return '';
  },

  /**
   * Return the base path to upload resource based on suffix-rol(owner or user)
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {string} base path
   */
  basePathUserToUploadResources(state, getters) {
    if (getters.isUserLoggedIn) {
      return `${getters.tenantId}/${getters.userId}`;
    }

    return '';
  },

  /**
   * Return the avatar url
   *
   * @param {Object} state - state of module
   * @return {string} avatar url
   */
  avatarUrl(state) {
    return state.user.avatar ? state.user.avatar.url : '';
  },

  /**
   * Return the avatar bucket path
   *
   * @param {Object} state - state of module
   * @return {string} avatar url
   */
  avatarBucketPath(state) {
    return state.user.avatar ? state.user.avatar.bucketPath : '';
  },

  /**
   * Indicate if the auth user has any prepaid package
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {boolean} true if auth user is a merchant and has any prepaid package
   */
  userHasAnyPrepaidPackage(state, getters) {
    return getters.tenant
      && Array.isArray(getters.tenant.prepaidPackages)
      && getters.tenant.prepaidPackages.length > 0;
  },

  /**
   * Indicate if the auth user has one monthly package
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {boolean} true if auth user has one active monthly package
   */
  userHasMonthlyPackage(state, getters) {
    return getters.tenant
      && getters.tenant.monthlyPackage !== null
      && typeof getters.tenant.monthlyPackage === 'object';
  },

  /**
   * Indicate the number monthly package of user
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {number | null} number of package if have any, otherwise null
   */
  userMonthlyPackageNumber(state, getters) {
    return getters.tenant
      && getters.userHasMonthlyPackage
      ? getters.tenant.monthlyPackage.packageNumber
      : null;
  },

  /**
   * Indicate if the auth user has any package (prepaid or monthly)
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {boolean} true if auth user has any plan (prepaid or monthly)
   */
  userHasAnyPackage(state, getters) {
    return getters.userHasAnyPrepaidPackage || getters.userHasMonthlyPackage;
  },

  /**
   * return the monthly package of user if has any
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {Object | null} monthly package of user if has any, otherwise null
   */
  userMonthlyPackage(state, getters) {
    return getters.userHasMonthlyPackage
      ? getters.tenant.monthlyPackage
      : null;
  },

  /**
   * return the prepaid packages of user
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {Object[]} prepaid packages of user
   */
  userPrepaidPackages(state, getters) {
    return getters.userHasAnyPrepaidPackage
      ? getters.tenant.prepaidPackages
      : [];
  },

  /**
   * Return the balance of active monthly package for user if he has any
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {number} balance of active monthly package for user
   */
  balanceMonthlyPackage(state, getters) {
    return getters.userHasMonthlyPackage
      ? getters.tenant.monthlyPackage.balance || 0
      : 0;
  },

  /**
   * Return the prepaid packages balance of user if he has any
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {number} prepaid packages balance of user
   */
  balancePrepaidPackages(state, getters) {
    let balance = 0;

    if (getters.userHasAnyPrepaidPackage) {
      getters.tenant.prepaidPackages.forEach((el) => {
        // eslint-disable-next-line no-restricted-globals
        balance += isNaN(el.balance) ? 0 : el.balance;
      });
    }

    return balance;
  },

  /**
   * Return the prepaid packages balance of user if he has any
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {number} prepaid packages balance of user
   */

  balanceFrozenPrepaidPackages(state, getters) {
    let balanceFrozen = 0;

    getters.tenant.prepaidPackages.forEach((el) => {
      // eslint-disable-next-line no-restricted-globals
      if (el.balanceFrozen) {
        Object.values(el.balanceFrozen).forEach((balFrozen) => {
          balanceFrozen += isNaN(balFrozen) ? 0 : balFrozen;
        });
      }
    });

    return balanceFrozen;
  },
  /**
   * Return the balance of user if he has any
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @return {number} balance of user
   */
  balance(state, getters) {
    return Number(
      Number((getters.balancePrepaidPackages - getters.balanceFrozenPrepaidPackages) + getters.balanceMonthlyPackage)
        .toFixed(2),
    );
  },

  /**
   * return the permission of the auth user for the one resource
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @param {boolean} isPublic - indicate if the resource is public
   * @param {Array.<Object>} userPrivateAccess - array of users ids to have access to the resource
   * @param {string} creator - creator id of the resource
   * @return {string|undefined} permission for the resource
   */
  getAuthUserPermissionOfSharedResource: (state, getters) => ({
    isPublic,
    usersPrivateAccess,
    creator,
  }) => {
    let permission = enums.SharedPermission.NONE;

    if (getters.userId === creator || getters.isOwner || getters.isAdmin) {
      permission = enums.SharedPermission.ALL;
    } else if (isPublic) {
      permission = enums.SharedPermission.VIEW;
    } else {
      const access = usersPrivateAccess.find((a) => a.user === getters.userId);

      if (access) {
        permission = access.permission;
      }
    }

    return permission;
  },

  /**
   * indicate if the user has one permission
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @param {string} permission - permission to check in the auth user
   * @return {boolean} indicate if the auth user has the requested permission
   */
  userHasPermissionTo: (state, getters) => (permission) => {
    if (getters.isOwner || getters.isAdmin) {
      return true;
    }

    const userPermission = getters.permissions.find((p) => p.permission === permission);

    return userPermission && userPermission.access;
  },

  /**
   * indicate if the user has one permission
   *
   * @param {Object} state - state of module
   * @param {Object} getters - getters of module
   * @param {boolean} all - indicate if user must be all permissions
   * @param {string[]} permissions - permissions to check in the auth user
   * @return {boolean} indicate if the auth user has the requested permission
   */
  userHasPermissionsTo: (state, getters) => ({ all = false, permissions = [] }) => {
    if (getters.isOwner || getters.isAdmin) {
      return true;
    }

    if (all) {
      return permissions.every((per) => getters.userHasPermissionTo(per));
    }

    return permissions.some((per) => getters.userHasPermissionTo(per));
  },
};
