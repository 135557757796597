/**
 * Store tag module.
 *
 * @module store/audiences/tag/module
 * @author Dilan Useche <dilan8810@gmail.com>
 * */

import actions from './tag.actions';

/**
 * export default
 *  @type {VuexModule}
 * */
export default {
  namespaced: true,
  actions,
};
