import userService from '../../api/modules/user.service';

/**
 * Actions of the store user module.
 *
 * Use the user service to connect to the api. See {@link module:api/modules/user}
 * @module store/user/actions
 * @author Dilan Useche <dilan8810@gmail.com>
 * */
export default {
  /**
   * Get all users in the organization that the requesting user has access to
   *
   * Use getAll User service to fetch the users. See {@link module:api/modules/user~getAll}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} params - query params
   * @return {Promise.<Array.<Object> | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async fetchAllUsers({ commit }, params = {}) {
    const resp = await userService.getAll(params);
    return resp.data;
  },

  /**
   * Get all merchants in the organization that the requesting user has access to
   *
   * Use getAllMerchants User service to fetch the users.
   * See {@link module:api/modules/user~getAll}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} params - query params
   * @return {Promise.<Array.<Object> | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async fetchAllMerchants({ commit }, params) {
    const resp = await userService.getAllMerchants(params);
    return resp.data;
  },

  /**
   * Get one user by id
   *
   * Use getOne User service to fetch the user
   * See {@link module:api/modules/user~getOne}.
   * @param {Object} getters - vuex getters user module
   * @param {string} userId - id of user to get
   * @return {Promise<Object>} user
   */
  // eslint-disable-next-line no-unused-vars
  async fetchUser({ getters }, userId) {
    const resp = await userService.getOne({ id: userId });
    return resp.data;
  },

  /**
   * Invite new users.
   *
   * Use invite User service to create the user. See {@link module:api/modules/user~edit}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} invitation - users info to invite
   * @return {Promise.<Array.<Object> | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async inviteUser({ commit }, invitation) {
    const resp = await userService.invite({
      users: invitation,
      params: {},
    });

    return resp.data;
  },

  /**
   * Export many users from file.
   *
   * Use exportFile user service to export the users.
   * See {@link module:api/modules/user~exportFile}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} exportInfo - users export info
   * @return {Promise<string | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async exportFile({ commit }, exportInfo) {
    const resp = await userService.exportFile(exportInfo);
    return resp.data;
  },

  /**
   * set one campaign as draft.
   *
   * @param {Function} commit - vuex store commit function
   * @param {Object} campaignDraft - Campaign to set as draft
   * @param {string} campaignType - Campaign type
   * @param {boolean} hardDraft - indicate if draft must to be save in campaign list or no
   * @return {Promise.<Object | null>}
   */
  async saveDraftCampaign({ commit }, {
    campaignDraft,
    hardDraft = false,
  }) {
    const { id, ...payload } = campaignDraft;

    const resp = await userService.saveDraftCampaign({
      payload,
      campaignId: id || null,
      hardDraft,
    });

    commit('auth/UPDATE_CAMPAIGN_DRAFT_AUTH_USER', resp.data, { root: true });

    return resp.data;
  },

  /**
   * Discard a draft campaign.
   *
   * @param {Function} commit - vuex store commit function
   * @param {string | null} campaignId - id of campaign to save as draft
   * @param {string} campaignType - Campaign type
   * @return {Promise.<Object | null>}
   */
  async discardDraftCampaign({ commit }, {
    campaignId = null,
  }) {
    const resp = await userService.discardDraftCampaign({
      campaignId: campaignId || null,
    });

    commit('auth/UPDATE_CAMPAIGN_DRAFT_AUTH_USER', resp.data, { root: true });

    return resp.data;
  },

  /**
   * set one campaign as draft.
   *
   * @param {Function} commit - vuex store commit function
   * @param {Object} payload - Campaign to set as draft
   * @return {Promise.<Object | null>}
   */
  async saveDraftQuickCampaign({ commit }, campaignDraft) {
    const { id, ...payload } = campaignDraft;

    const resp = await userService.saveDraftQuickCampaign(payload);

    commit('auth/UPDATE_QUICK_CAMPAIGN_DRAFT_AUTH_USER', resp.data, { root: true });

    return resp.data;
  },

  /**
   * Discard a draft campaign.
   *
   * @param {Function} commit - vuex store commit function
   * @return {Promise.<Object | null>}
   */
  async discardDraftQuickCampaign({ commit }) {
    const resp = await userService.discardDraftQuickCampaign();

    commit('auth/DELETE_QUICK_CAMPAIGN_DRAFT', resp.data, { root: true });

    return resp.data;
  },

  /**
   * Update and commit one user.
   *
   * Use edit User service to update the user. See {@link module:api/modules/user~create}.
   * @param {Function} commit - vuex store commit function
   * @param {number} id - id of user to update
   * @param {string} id - id of caller id to update
   * @param {Object} user - user to update
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async editUser({ commit }, { id, ...user }) {
    const resp = await userService.edit({
      id,
      payload: user,
    });

    return resp.data;
  },

  /**
   * Delete one user by id
   *
   * Use delete User service to delete the user. See {@link module:api/modules/user~delete}.
   * @param {Function} commit - vuex store commit function
   * @param {string} userId - id of user to delete
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async removeUser({ commit }, userId) {
    const resp = await userService.delete(userId);
    return resp.data;
  },

  /**
   * Delete many users by id
   *
   * Use deleteMany User service to delete the users.
   * See {@link module:api/modules/user~deleteMany}.
   * @param {Function} commit - vuex store commit function
   * @param {Array.<Object>} data - users to delete
   * @param {Object} filters - filters to search callers ids to delete
   * @return {Promise.<any>}
   */
  // eslint-disable-next-line no-unused-vars
  async removeUsers({ commit }, { data, filters = {} }) {
    const resp = await userService.deleteMany({
      data: data.map((user) => user.id),
      filters,
    });

    return resp.data;
  },
};
