/**
 * Store app service module.
 *
 * @module store/app-service/module
 * @author Dilan Useche <dilan8810@gmail.com>
 * */

import actions from './app-service.actions';

/**
 * export default
 *  @type {VuexModule}
 * */
export default {
  namespaced: true,
  actions,
};
