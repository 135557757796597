import configApi from '@/api/config';
import BaseService from '@/api/base.service';

/**
 * segment service api
 * @class SegmentService
 */
class SegmentService extends BaseService {
  constructor() {
    super({ baseUrl: configApi.baseRoutesUrls.segments });
  }
}

const singletonInstance = new SegmentService();

/**
 * segment service api instance
 * @exports api/modules/audiences/segment
 * */
export default singletonInstance;
