/**
 * Store attribute module.
 *
 * @module store/audiences/attribute/module
 * @author Dilan Useche <dilan8810@gmail.com>
 * */

import actions from './attribute.actions';

/**
 * export default
 *  @type {VuexModule}
 * */
export default {
  namespaced: true,
  actions,
};
