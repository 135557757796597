/**
 * Mutations of the store SMS Campaign module.
 *
 * @module store/campaigns/sms-campaign/mutations
 * @author Dilan Useche <dilan8810@gmail.com>
 * */
export default {
  /**
   * set the fromSMSTemplate of SMS Campaigns
   *
   * @param {Object} state - vuex SMS Campaign state
   * @param {Object | null} smsTemplate - SMS template to set
   * @return {void}
   */
  SET_FROM_SMS_TEMPLATE(state, smsTemplate) {
    state.fromSMSTemplate = smsTemplate;
  },

  /**
   * set SMS Campaign payload to edit
   *
   * @param {Object} state - vuex user state
   * @param {Object | null} campaignPayload - SMS Campaign payload
   * @return {void}
   */
  SET_SMS_CAMPAIGN_PAYLOAD(state, campaignPayload) {
    state.payload = campaignPayload;
  },
};
