import BaseService from '@/api/base.service';
import configApi from '@/api/config';
import grapePerksAppService from '@/api';

/**
 * user service api
 * @class UserService
 */
class UserService extends BaseService {
  constructor() {
    super({ baseUrl: configApi.baseRoutesUrls.users });
  }

  /**
   * Get the flags of user
   * @function getFlags
   * @return {Promise.<GrapePerksApiResponse>} flags of user
   */
  getFlags = () => grapePerksAppService.get(
    `${this.baseUrl}/flags`,
  ).then((resp) => resp.data);

  /**
   * Get all merchants in the organization
   * @function getAllMerchants
   * @param {Object} params - query params
   * @return {Promise.<GrapePerksApiResponse>} user of the organization
   */
  getAllMerchants = (params) => grapePerksAppService.get(
    `${this.baseUrl}/merchants`, { params },
  ).then((resp) => resp.data);

  /**
   * Request the url of the qr code for the user
   *
   * @function getUrlQrCodeTwoFactorAuthentication
   * @return {Promise.<GrapePerksApiResponse>}
   */
  getUrlQrCodeTwoFactorAuthentication = () => grapePerksAppService.get(
    `${this.baseUrl}/qr-for-2fa`,
  ).then((resp) => resp.data);

  /**
   * Invite many users
   * @function invite
   * @param {Object} users - users info to invite
   * @param {Object} params - params of request
   * @return {Promise.<GrapePerksApiResponse>} users invited
   */
  invite = ({ users, params = {} }) => grapePerksAppService.post(
    `${this.baseUrl}/invite`, users, { params },
  ).then((resp) => resp.data);

  /**
   * Validate the code to activate the two factor authentication option
   *
   * @function validateTwoFactorAuthentication
   * @param {string} code - The code token in the app authenticator
   * @return {Promise.<GrapePerksApiResponse>}
   */
  enableTwoFactorAuthentication = (code) => grapePerksAppService.post(
    `${this.baseUrl}/enable-2fa/${code}`,
  ).then((resp) => resp.data);

  /**
   * Request a code to user email for disable 2FA
   *
   * @function sentEmailCodeToDisabled2FA
   * @return {Promise.<GrapePerksApiResponse>}
   */
  sentEmailCodeToDisabled2FA = () => grapePerksAppService.get(
    `${this.baseUrl}/disable-2fa/sent-email-code`,
  ).then((resp) => resp.data);

  /**
   * Verify the code to disable the two-factor authentication option
   *
   * @function disableTwoFactorAuthentication
   * @param {string} payload - payload to validate the action
   * @return {Promise.<GrapePerksApiResponse>}
   */
  disableTwoFactorAuthentication = (payload) => grapePerksAppService.post(
    `${this.baseUrl}/disable-2fa/${payload}`,
  ).then((resp) => resp.data);

  /**
   * Verify the code to disable the two-factor authentication option
   *
   * @function disableTwoFactorAuthentication
   * @param {string} code - code to validate the action
   * @return {Promise.<GrapePerksApiResponse>}
   */
  disableTwoFactorAuthenticationByEmailCode = (code) => grapePerksAppService.post(
    `${this.baseUrl}/disable-2fa/by-email/${code}`,
  ).then((resp) => resp.data);

  /**
   * Update user flags
   * @function updateFlags
   * @param {Object} payload - flags payload
   * @return {Promise.<GrapePerksApiResponse>}
   */
  updateFlags = (payload) => grapePerksAppService.put(
    `${this.baseUrl}/flags`, payload,
  ).then((resp) => resp.data);

  /**
   * Update user markers
   * @function updateMarkers
   * @param {string[]} payload - markers payload
   * @return {Promise.<GrapePerksApiResponse>}
   */
  updateMarkers = (payload) => grapePerksAppService.put(
    `${this.baseUrl}/markers`, payload,
  ).then((resp) => resp.data);

  /**
   * Update user avatar
   * @function updateAvatar
   * @param {Object} payload - avatar payload
   * @return {Promise.<GrapePerksApiResponse>}
   */
  updateAvatar = (payload) => grapePerksAppService.put(
    `${this.baseUrl}/avatar`, payload,
  ).then((resp) => resp.data);

  /**
   * Verify new profile email
   *
   * @function verifyProfileEmail
   * @param {Object} payload - profile payload
   * @return {Promise.<GrapePerksApiResponse>}
   */
  verifyProfileEmail = ({ email, code, token }) => grapePerksAppService.put(
    `${this.baseUrl}/change-email-verification?${token ? `token=${token}` : `code=${code}`}`,
    { email },
  ).then((resp) => resp.data);

  /**
   * Update user profile
   * @function updateProfile
   * @param {Object} payload - profile payload
   * @return {Promise.<GrapePerksApiResponse>}
   */
  updateProfile = (payload) => grapePerksAppService.put(
    `${this.baseUrl}/profile`, payload,
  ).then((resp) => resp.data);

  /**
   * disconnect google account from auth user
   * @function disconnectToGoogle
   * @return {Promise.<GrapePerksApiResponse>}
   */
  disconnectToGoogle = () => grapePerksAppService.put(
    `${this.baseUrl}/disconnect-google-account`, {},
  ).then((resp) => resp.data);

  /**
   * Update user password
   * @function updateProfile
   * @param {Object} payload - password payload
   * @return {Promise.<GrapePerksApiResponse>}
   */
  updatePassword = (payload) => grapePerksAppService.put(
    `${this.baseUrl}/password`, payload,
  ).then((resp) => resp.data);

  /**
   * Save draft quick campaign in user
   * @function saveDraftQuickCampaign
   * @param {Object} payload - Campaign to set as draft
   * @return {Promise.<GrapePerksApiResponse>}
   */
  saveDraftQuickCampaign = (payload) => grapePerksAppService.put(
    `${this.baseUrl}/save-draft-quick-campaign/`, payload,
  ).then((resp) => resp.data);

  /**
   * Save draft campaign in user
   * @function discardDraftCampaign
   * @param {string | null} campaignId - id of campaign to save as draft
   * @return {Promise.<GrapePerksApiResponse>}
   */
  discardDraftCampaign = ({ campaignId }) => grapePerksAppService.put(
    `${this.baseUrl}/discard-draft-campaign/${campaignId ? `${campaignId}` : ''}`,
    {},
  ).then((resp) => resp.data);

  /**
   * Save draft campaign in user
   * @function saveDraftCampaign
   * @param {Object} payload - Campaign to set as draft
   * @param {string | null} campaignId - id of campaign to save as draft
   * @param {boolean} hardDraft - indicate if draft must save in campaign list or no
   * @return {Promise.<GrapePerksApiResponse>}
   */
  saveDraftCampaign = ({
    payload,
    campaignId = null,
    hardDraft = false,
  }) => grapePerksAppService.put(
    `${this.baseUrl}/save-draft-campaign/${campaignId ? `${campaignId}` : ''}`,
    payload, { params: { hardDraft } },
  ).then((resp) => resp.data);

  /**
   * Discard draft quick campaign in user
   * @function discardDraftCampaign
   * @return {Promise.<GrapePerksApiResponse>}
   */
  discardDraftQuickCampaign = () => grapePerksAppService.put(
    `${this.baseUrl}/discard-draft-quick-campaign`,
    {},
  ).then((resp) => resp.data);

  /**
   * remove user avatar
   * @function removeAvatar
   * @return {Promise.<GrapePerksApiResponse>}
   */
  removeAvatar = () => grapePerksAppService.delete(
    `${this.baseUrl}/avatar`,
  ).then((resp) => resp.data);
}

const singletonInstance = new UserService();

/**
 * user service api instance
 * @exports api/modules/user
 * */
export default singletonInstance;
