function getQuestionUrl(category, question) {
  const questionEscaped = question.title
    ? question.title.toLowerCase().replaceAll(' ', '-')
    : question.question.id;
  return `/documentation/${category.id}/${questionEscaped}`;
}

export default {
  categoriesWithRelated(state) {
    return state.data.map((category) => {
      const sections = category.sections.map((section) => {
        const questions = section.questions.map((question) => {
          const relatedQuestions = question.relatedQuestions.map((related) => {
            const categoryRelated = state.data.find((c) => c.id === related.categoryId);

            if (!categoryRelated) {
              return related;
            }

            const sectionRelated = categoryRelated.sections.find(
              (s) => s.id === related.sectionId,
            );

            if (!sectionRelated) {
              return related;
            }

            const questionRelated = sectionRelated.questions.find(
              (q) => q.id === related.questionId,
            );

            if (!questionRelated) {
              return related;
            }

            return {
              ...related,
              title: questionRelated.title,
              url: getQuestionUrl(categoryRelated, questionRelated),
            };
          });
          return {
            ...question,
            relatedQuestions,
          };
        });

        return {
          ...section,
          questions,
        };
      });

      return {
        ...category,
        sections,
      };
    });
  },
  getQuestionByIds: (state, getters) => ({
    categoryId,
    sectionId,
    questionId,
  }) => {
    const category = getters.categoriesWithRelated.find((c) => c.id === categoryId);

    if (!category) {
      return null;
    }

    const section = category.sections.find((s) => s.id === sectionId);

    if (!section) {
      return null;
    }

    const question = section.questions.find((q) => q.id === questionId);

    if (!question) {
      return null;
    }

    return question;
  },
};
