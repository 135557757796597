import grapePerksAppService from '@/api/index';
import enums from '@/enums';

/**
 * Base service api
 * @class BaseService
 */
export default class BaseService {
  constructor({ baseUrl }) {
    this.baseUrl = baseUrl;
  }

  /**
   * Get count of collection
   * @function getCount
   * @param {Object} payload - params to find
   * @return {Promise.<GrapePerksApiResponse | any>} count
   */
  getCountWithFilters = (payload) => grapePerksAppService.get(
    `${this.baseUrl}/count-with-filters`, { params: payload },
  ).then((resp) => resp.data);

  /**
   * Get count of collection
   * @function getCount
   * @param {Object} params - params to retrieve
   * @return {Promise.<GrapePerksApiResponse | any>} count
   */
  getCount = (params = {}) => grapePerksAppService.get(
    `${this.baseUrl}/count`, { params },
  ).then((resp) => resp.data);

  /**
   * Get all items from a collection
   * @function getAll
   * @param {Object} params - query params
   * @param {Object[]} params.sortBy - query sort by fields
   * @param {Object} params.filters - query filters
   * @param {Object} params.filtersMatch - query filters match
   * @param {number} params.skip - query skip
   * @param {number} params.limit - query limit
   * @return {Promise.<GrapePerksApiResponse | any>} items requested
   */
  getAll = ({
    sortBy = [],
    filters = {},
    filtersMatch = enums.AppFilter.FilterMathType.ALL,
    skip = 0,
    limit = 0,
    ...params
  }) => grapePerksAppService.get(this.baseUrl, {
    params: {
      ...params,
      sortBy,
      filters,
      filtersMatch,
      skip,
      limit,
    },
  }).then((resp) => resp.data);

  /**
   * Get one item by id
   * @function getOne
   * @param {string} id - id of item to retrieve
   * @param {Object} params - params to retrieve
   * @return {Promise.<GrapePerksApiResponse | any>} item requested
   */
  getOne = ({ id, params = {} }) => grapePerksAppService.get(
    `${this.baseUrl}/${id}`, { params },
  ).then((resp) => resp.data);

  /**
   * Create one item
   * @function create
   * @param {Object} payload - payload to create
   * @return {Promise.<GrapePerksApiResponse | any>} item created
   */
  create = (payload) => grapePerksAppService.post(
    this.baseUrl, payload,
  ).then((resp) => resp.data);

  /**
   * import many items from file
   * @function importFromFile
   * @param {Array.<Object>} payloads - items to import
   * @return {Promise.<GrapePerksApiResponse | any>} import result
   */
  importFromFile = (payloads) => grapePerksAppService.post(
    `${this.baseUrl}/import-from-file`, payloads,
  ).then((resp) => resp.data);

  /**
   * export items to file
   * @function importFromFile
   * @param {Object} dataRequest - data of request to export items
   * @return {Promise.<GrapePerksApiResponse | any>} url to download file exported
   */
  exportFile = (dataRequest) => grapePerksAppService.post(
    `${this.baseUrl}/export-file`, dataRequest,
  ).then((resp) => resp.data);

  /**
   * Update one item
   * @function edit
   * @param {string} id - id of item to update
   * @param {Object} payload - payload to update
   * @return {Promise.<GrapePerksApiResponse | any>} item updated
   */
  edit = ({ id, payload }) => grapePerksAppService.put(
    `${this.baseUrl}/${id}`, payload,
  ).then((resp) => resp.data);

  /**
   * Delete one item
   * @function delete
   * @param {string} id - id of item to delete
   * @return {Promise.<GrapePerksApiResponse | any>}
   */
  delete = (id) => grapePerksAppService.delete(
    `${this.baseUrl}/${id}`,
  ).then((resp) => resp.data);

  /**
   * Delete many items
   * @function deleteMany
   * @param {Object} dataRequest - data of request to delete items
   * @return {Promise.<GrapePerksApiResponse | any>}
   */
  deleteMany = (dataRequest) => grapePerksAppService.patch(
    this.baseUrl, dataRequest,
  ).then((resp) => resp.data);
}
