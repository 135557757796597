/**
 * @typedef InboundSmsStateModule
 * @type {object}
 */

/**
 * State of the store inbound-sms module.
 *
 * @module store/inbound-sms/state
 * @author Dilan Useche <dilan8810@gmail.com>
 * */

/**
 * export default
 *  @type {InboundSmsStateModule}
 * */
export default {
};
