import grapePerksAppService from '@/api/index';
import BaseService from '@/api/base.service';

/**
 * resource service api
 * @class ResourceService
 */
export default class ResourceService extends BaseService {
  constructor({ baseUrl }) {
    super({ baseUrl });
  }

  /**
   * Get all items in the trash
   * @function getAllFromTrash
   * @param {Object} params - query params
   * @return {Promise.<GrapePerksApiResponse | any>} items of the auth user in the trash
   */
  getAllFromTrash = (params) => grapePerksAppService.get(
    `${this.baseUrl}/trash`, { params },
  ).then((resp) => resp.data);

  /**
   * export items to file
   * @function importFromFile
   * @param {Object} dataRequest - data of request to export items
   * @return {Promise.<GrapePerksApiResponse | any>} url to download file exported
   */
  exportFile = (dataRequest, trash = false) => grapePerksAppService.post(
    `${this.baseUrl}/export-file?trash=${trash}`, dataRequest,
  ).then((resp) => resp.data);

  /**
   * Delete one item
   * @function delete
   * @param {string} id - id of item to delete
   * @param {boolean} trash - indicate if contact is from trash
   * @return {Promise.<GrapePerksApiResponse | any>}
   */
  delete = (id, trash = false) => grapePerksAppService.delete(
    `${this.baseUrl}/${id}?trash=${trash}`,
  ).then((resp) => resp.data);

  /**
   * Restore one items
   * @function restore
   * @param {string} id - id of item to restore
   * @return {Promise.<GrapePerksApiResponse | any>}
   */
  restore = (id) => grapePerksAppService.put(
    `${this.baseUrl}/restore/${id}`, {},
  ).then((resp) => resp.data);

  /**
   * Delete many items
   * @function deleteMany
   * @param {Object} dataRequest - data of request to delete items
   * @param {boolean} trash - indicate if contacts are from trash
   * @return {Promise.<GrapePerksApiResponse | any>}
   */
  deleteMany = (dataRequest, trash = false) => grapePerksAppService.patch(
    `${this.baseUrl}?trash=${trash}`, dataRequest,
  ).then((resp) => resp.data);

  /**
   * Restore many items
   * @function restoreMany
   * @param {Object} dataRequest - data of request to restore items
   * @return {Promise.<GrapePerksApiResponse | any>}
   */
  restoreMany = (dataRequest) => grapePerksAppService.patch(
    `${this.baseUrl}/restore`, dataRequest,
  ).then((resp) => resp.data);
}
