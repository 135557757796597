export default function VueElementLoadingPlugin(Vue) {
  Vue.mixin({
    data() {
      return {
        showAppLoading: false,
        preventAppLoading: false,
      };
    },
  });
}
