import smsTemplateService from '@/api/modules/sms-template.service';

/**
 * Actions of the store sms template module.
 *
 * Use the sms template service to connect to the api.
 * See {@link module:api/modules/templates/sms-template}
 * @module store/templates/sms-template/actions
 * @author Dilan Useche <dilan8810@gmail.com>
 * */
export default {
  /**
   * Get sms templates in the organization that the requesting user has access to
   *
   * Use getAll sms template service to fetch the sms templates.
   * See {@link module:api/modules/templates/sms-template~getAll}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} params - query params
   * @return {Promise.<Array.<Object> | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async fetchAllSMSTemplates({ commit }, params = {}) {
    const resp = await smsTemplateService.getAll(params);
    return resp.data;
  },

  /**
   * Get sms templates in the organization that the requesting user has access to in the trash
   *
   * Use getAllFromTrash sms template service to fetch the sms templates.
   * See {@link module:api/modules/templates/sms-template~getAllFromTrash}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} params - query params
   * @return {Promise.<Array.<Object> | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async fetchAllSMSTemplatesFromTrash({ commit }, params) {
    const resp = await smsTemplateService.getAllFromTrash(params);
    return resp.data;
  },

  /**
   * Get one sms template by id
   *
   * Use getOne sms template service to fetch the sms template
   * See {@link module:api/modules/templates/sms-template~getOne}.
   * @param {Object} getters - vuex getters of sms template module
   * @param {Object} state - vuex state of sms template module
   * @param {string} smsTemplateId - id of sms template to get
   * @return {Promise.<Object>} sms template
   */
  // eslint-disable-next-line no-unused-vars
  async fetchSMSTemplate({ getters }, smsTemplateId) {
    const resp = await smsTemplateService.getOne({ id: smsTemplateId });
    return resp.data;
  },

  /**
   * Create a new sms template.
   *
   * Use create sms template service to create the sms template.
   * See {@link module:api/modules/templates/sms-template~create}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} smsTemplate - sms template to create
   * @return {Promise.<Object | any>}
   */
  async addSMSTemplate({ commit }, smsTemplate) {
    const resp = await smsTemplateService.create(smsTemplate);
    return resp.data;
  },

  /**
   * Import many sms templates from file.
   *
   * Use importFromFile sms template service to import the sms templates.
   * See {@link module:api/modules/templates/sms-template~importFromFile}.
   * @param {Function} commit - vuex store commit function
   * @param {Array<Object>} smsTemplates - sms templates to import
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async importFromFile({ commit }, smsTemplates) {
    const resp = await smsTemplateService.importFromFile(smsTemplates);
    return resp.data;
  },

  /**
   * Export many sms templates from file.
   *
   * Use exportFile sms template service to export the sms templates.
   * See {@link module:api/modules/templates/sms-template~importFromFile}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} exportInfo - sms templates export info
   * @return {Promise<string | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async exportFile({ commit }, { exportInfo, trash = false }) {
    const resp = await smsTemplateService.exportFile(exportInfo, trash);
    return resp.data;
  },

  /**
   * Update and commit one sms template.
   *
   * Use edit sms template service to update the sms template.
   * See {@link module:api/modules/templates/sms-template~edit}.
   * @param {Function} commit - vuex store commit function
   * @param {string} id - id of sms template to update
   * @param {Object} smsTemplate - sms template to update
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async editSMSTemplate({ commit }, { id, ...smsTemplate }) {
    await smsTemplateService.edit({
      id,
      payload: smsTemplate,
    });
  },

  /**
   * Restore one sms template in the trash.
   *
   * Use restore sms template service to restore the sms template.
   * See {@link module:api/modules/templates/sms-template~restore}.
   * @param {Function} commit - vuex store commit function
   * @param {string} smsTemplateId - id of sms template to update
   * @return {Promise.<any>}
   */
  // eslint-disable-next-line no-unused-vars
  async restoreSMSTemplate({ commit }, smsTemplateId) {
    const resp = await smsTemplateService.restore(smsTemplateId);
    return resp.data;
  },

  /**
   * Delete one sms template by id
   *
   * Use delete sms template service to delete the sms template.
   * See {@link module:api/modules/templates/sms-template~delete}.
   * @param {Function} commit - vuex store commit function
   * @param {string} smsTemplateId - id of sms template to delete
   * @return {Promise.<any>}
   */
  // eslint-disable-next-line no-unused-vars
  async removeSMSTemplate({ commit }, { smsTemplateId, trash = false }) {
    const resp = await smsTemplateService.delete(smsTemplateId, trash);
    return resp.data;
  },

  /**
   * Delete many sms templates by id
   *
   * Use deleteMany sms template service to delete the sms templates.
   * See {@link module:api/modules/templates/sms-template~deleteMany}.
   * @param {Function} commit - vuex store commit function
   * @param {Array.<Object> | null} data - sms templates to delete
   * @param {Object} filters - filters to search sms templates to delete
   * @return {Promise.<any>}
   */
  // eslint-disable-next-line no-unused-vars
  async removeSMSTemplates({ commit }, { data = null, filters = {}, trash = false }) {
    const resp = await smsTemplateService.deleteMany({
      data: data ? data.map((t) => t.id) : null,
      filters,
    }, trash);

    return resp.data;
  },

  /**
   * Restore many sms templates
   *
   * Use restoreMany sms template service to restore the sms templates.
   * See {@link module:api/modules/templates/sms-template~restoreMany}.
   * @param {Function} commit - vuex store commit function
   * @param {Array.<Object> | null} data - sms templates to restore
   * @param {Object} filters - filters to search sms templates to restore
   * @return {Promise.<any>}
   */
  // eslint-disable-next-line no-unused-vars
  async restoreSMSTemplates({ commit }, { data = null, filters = {} }) {
    const resp = await smsTemplateService.restoreMany({
      data: data ? data.map((t) => t.id) : null,
      filters,
    });

    return resp.data;
  },
};
