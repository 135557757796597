export default {
  data: [
    {
      id: 'accounts',
      title: 'Accounts',
      description: 'Set up your account, learn about billing, and stay up-to-date on compliance policies.',
      graphic: require('@/assets/img/vuexy/pages/graphic-1.png'),
      sections: [
        {
          id: 0,
          title: 'Section 1',
          questions: [
            {
              id: 0,
              title: 'Cake icing gummi bears?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 1,
              title: 'Jelly soufflé apple pie?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 2,
              title: 'Soufflé apple pie ice cream cotton?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
          ],
        },
        {
          id: 1,
          title: 'Section 2',
          questions: [
            {
              id: 0,
              title: 'Cake icing gummi bears?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 1,
              title: 'Jelly soufflé apple pie?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 2,
              title: 'Soufflé apple pie ice cream cotton?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
          ],
        },
        {
          id: 2,
          title: 'Section 3',
          questions: [
            {
              id: 0,
              title: 'Cake icing gummi bears?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 1,
              title: 'Jelly soufflé apple pie?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 2,
              title: 'Soufflé apple pie ice cream cotton?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'audiences',
      title: 'Audiences',
      description: 'Import contacts and manage your audiences.',
      graphic: require('@/assets/img/vuexy/pages/graphic-2.png'),
      sections: [
        {
          id: 0,
          title: 'Section 1',
          questions: [
            {
              id: 0,
              title: 'Cake icing gummi bears?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 1,
              title: 'Jelly soufflé apple pie?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 2,
              title: 'Soufflé apple pie ice cream cotton?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
          ],
        },
        {
          id: 1,
          title: 'Section 2',
          questions: [
            {
              id: 0,
              title: 'Cake icing gummi bears?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 1,
              title: 'Jelly soufflé apple pie?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 2,
              title: 'Soufflé apple pie ice cream cotton?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
          ],
        },
        {
          id: 2,
          title: 'Section 3',
          questions: [
            {
              id: 0,
              title: 'Cake icing gummi bears?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 1,
              title: 'Jelly soufflé apple pie?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 2,
              title: 'Soufflé apple pie ice cream cotton?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'sms-marketing-campaigns',
      title: 'SMS marketing campaigns',
      description: 'Learn how to deliver and schedule SMS campaigns to increase your sales.',
      graphic: require('@/assets/img/vuexy/pages/graphic-3.png'),
      sections: [
        {
          id: 0,
          title: 'Section 1',
          questions: [
            {
              id: 0,
              title: 'Cake icing gummi bears?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 1,
              title: 'Jelly soufflé apple pie?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 2,
              title: 'Soufflé apple pie ice cream cotton?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
          ],
        },
        {
          id: 1,
          title: 'Section 2',
          questions: [
            {
              id: 0,
              title: 'Cake icing gummi bears?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 1,
              title: 'Jelly soufflé apple pie?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 2,
              title: 'Soufflé apple pie ice cream cotton?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
          ],
        },
        {
          id: 2,
          title: 'Section 3',
          questions: [
            {
              id: 0,
              title: 'Cake icing gummi bears?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 1,
              title: 'Jelly soufflé apple pie?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 2,
              title: 'Soufflé apple pie ice cream cotton?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'sms-transactional-campaigns',
      title: 'SMS transactional campaigns',
      description: 'Increase the value of your client relationships with personalised messages.',
      graphic: require('@/assets/img/vuexy/pages/graphic-4.png'),
      sections: [
        {
          id: 0,
          title: 'Section 1',
          questions: [
            {
              id: 0,
              title: 'Cake icing gummi bears?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 1,
              title: 'Jelly soufflé apple pie?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 2,
              title: 'Soufflé apple pie ice cream cotton?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
          ],
        },
        {
          id: 1,
          title: 'Section 2',
          questions: [
            {
              id: 0,
              title: 'Cake icing gummi bears?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 1,
              title: 'Jelly soufflé apple pie?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 2,
              title: 'Soufflé apple pie ice cream cotton?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
          ],
        },
        {
          id: 2,
          title: 'Section 3',
          questions: [
            {
              id: 0,
              title: 'Cake icing gummi bears?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 1,
              title: 'Jelly soufflé apple pie?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 2,
              title: 'Soufflé apple pie ice cream cotton?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'email-marketing',
      title: 'Email Marketing',
      description: 'Gummi bears pudding icing sweet caramels chocolate',
      graphic: require('@/assets/img/vuexy/pages/graphic-5.png'),
      sections: [
        {
          id: 0,
          title: 'Section 1',
          questions: [
            {
              id: 0,
              title: 'Cake icing gummi bears?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 1,
              title: 'Jelly soufflé apple pie?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 2,
              title: 'Soufflé apple pie ice cream cotton?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
          ],
        },
        {
          id: 1,
          title: 'Section 2',
          questions: [
            {
              id: 0,
              title: 'Cake icing gummi bears?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 1,
              title: 'Jelly soufflé apple pie?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 2,
              title: 'Soufflé apple pie ice cream cotton?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
          ],
        },
        {
          id: 2,
          title: 'Section 3',
          questions: [
            {
              id: 0,
              title: 'Cake icing gummi bears?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 1,
              title: 'Jelly soufflé apple pie?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 2,
              title: 'Soufflé apple pie ice cream cotton?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'demand-generation',
      title: 'Demand Generation',
      description: 'Dragée jelly beans candy canes pudding cake wafer. Muffin croissant.',
      graphic: require('@/assets/img/vuexy/pages/graphic-6.png'),
      sections: [
        {
          id: 0,
          title: 'Section 1',
          questions: [
            {
              id: 0,
              title: 'Cake icing gummi bears?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 1,
              title: 'Jelly soufflé apple pie?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 2,
              title: 'Soufflé apple pie ice cream cotton?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
          ],
        },
        {
          id: 1,
          title: 'Section 2',
          questions: [
            {
              id: 0,
              title: 'Cake icing gummi bears?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 1,
              title: 'Jelly soufflé apple pie?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 2,
              title: 'Soufflé apple pie ice cream cotton?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
          ],
        },
        {
          id: 2,
          title: 'Section 3',
          questions: [
            {
              id: 0,
              title: 'Cake icing gummi bears?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 1,
              title: 'Jelly soufflé apple pie?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
            {
              id: 2,
              title: 'Soufflé apple pie ice cream cotton?',
              img: require('@/assets/img/vuexy/pages/kb-article.jpg'),
              topics: ['Pastry jelly chocolate bar caramels', 'Donut chupa chups oat cake', 'Marshmallow icing topping toffee caramels dessert carrot cake'],
              textBeforeImage: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textBeforeTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              textAfterTopics: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cum deleniti quas sapiente! Accusantium, aspernatur consectetur deleniti ipsam necessitatibus omnis quo vitae voluptatibus. Dolorem, laudantium magni! Deserunt hic necessitatibus obcaecati perspiciatis?',
              relatedQuestions: [
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 1,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 0,
                  questionId: 2,
                },
                {
                  categoryId: 'accounts',
                  sectionId: 1,
                  questionId: 0,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  selectedCategory: null,
  selectedSection: null,
  selectedQuestion: null,
};
