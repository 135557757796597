import grapePerksAppService from '@/api';
import BaseService from '@/api/base.service';

/**
 * base campaign service api instance
 * @exports api/modules/campaigns/base-campaign
 * */

/**
 * base campaign service api
 * @class BaseCampaignService
 */
export default class BaseCampaignService extends BaseService {
  constructor({ baseUrl }) {
    super({ baseUrl });
  }

  /**
   * Return the analytics of the campaigns
   *
   * @function getAnalytics
   * @return {Promise.<GrapePerksApiResponse>} campaigns analytics
   */
  getAnalytics = (params) => grapePerksAppService.get(
    `${this.baseUrl}/analytics`, { params },
  ).then((resp) => resp.data);

  /**
   * Gte the count of campaigns by status
   *
   * @function getCountByStatus
   * @return {Promise.<GrapePerksApiResponse>} campaigns count by status
   */
  getCountByStatus = () => grapePerksAppService.get(
    `${this.baseUrl}/count-by-status`,
  ).then((resp) => resp.data);

  /**
   * return the cost of a Campaign
   * @function getCost
   * @param {Object} campaignInfo - campaign info to get cost
   * @return {Promise.<GrapePerksApiResponse>} Campaign cost
   */
  getCost = (campaignInfo) => grapePerksAppService.post(
    `${this.baseUrl}/get-cost`, campaignInfo,
  ).then((resp) => resp.data);

  /**
   * Get details of campaign by id
   * @function getDetails
   * @param {string} id - id of item to retrieve
   * @param {Object} params - params to retrieve
   * @return {Promise.<GrapePerksApiResponse | any>} item requested
   */
  getDetails = ({ id, params = {} }) => grapePerksAppService.get(
    `${this.baseUrl}/details/${id}`, { params },
  ).then((resp) => resp.data);

  /**
   * get test cost for one Campaign
   * @function getTestCost
   * @param {Object} payload - info of Campaign to test
   * @return {Promise.<GrapePerksApiResponse>}
   */
  getTestCost = (payload) => grapePerksAppService.post(
    `${this.baseUrl}/get-cost/test`, payload,
  ).then((resp) => resp.data);

  /**
   * Test one Campaign
   * @function test
   * @param {Object} payload - info of Campaign to test
   * @return {Promise.<GrapePerksApiResponse>}
   */
  test = (payload) => grapePerksAppService.post(
    `${this.baseUrl}/test`, payload,
  ).then((resp) => resp.data);
}
