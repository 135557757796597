import ResourceService from '@/api/resource.service';
import configApi from '@/api/config';

/**
 * sms template service api
 * @class SmsTemplateService
 */
class SmsTemplateService extends ResourceService {
  constructor() {
    super({ baseUrl: configApi.baseRoutesUrls.smsTemplates });
  }
}

const singletonInstance = new SmsTemplateService();

/**
 * sms template service api instance
 * @exports api/modules/templates/sms-template
 * */
export default singletonInstance;
