/**
 * @typedef VuexModule
 * @type {object}
 * @property {boolean} namespaced - have namespaced.
 * @property {Object} state - module state.
 * @property {Object} mutations - module mutations.
 * @property {Object} actions - module actions.
 * @property {Object} getters - module getters.
 */

/**
 * @typedef VuexStore
 * @type {object}
 * @property {Object} state - store state.
 * @property {Object} mutations - store mutations.
 * @property {Object} actions - store actions.
 * @property {Object} getters - store getters.
 * @property {Object} plugins - store plugins.
 * @property {Object} modules - store modules.
 * @property {boolean} strict - store strict mode.
 */

/**
 * App Store.
 *
 * @module
 * @author Dilan Useche <dilan8810@gmail.com>
 * */

import Vue from 'vue';
import Vuex from 'vuex';

import state from './state';
import getters from './getters';
import { mutations } from './mutations';
import actions from './actions';
import plugins from './plugins';

import auth from './auth/auth.module';
import notification from './notification/notification.module';
import user from './user/user.module';
import contact from './contact/contact.module';
import smsTemplate from './sms-template/sms-template.module';
import callerId from './caller-id/caller-id.module';
import smsCampaign from './sms-campaign/sms-campaign.module';
import tag from './tag/tag.module';
import segment from './segment/segment.module';
import attribute from './attribute/attribute.module';
import appService from './app-service/app-service.module';
import inboundSms from './inbound-sms/inbound-sms.module';
import documentation from './documentation/documentation.module';

Vue.use(Vuex);

/**
 * export default
 *  @type {VuexStore}
 * */
export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  plugins,
  modules: {
    auth,
    notification,
    user,
    tag,
    segment,
    attribute,
    contact,
    smsTemplate,
    callerId,
    smsCampaign,
    appService,
    inboundSms,
    documentation,
  },
  strict: process.env.NODE_ENV !== 'production',
});
