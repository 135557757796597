/*= ========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

export const STORAGE_AUTH_KEY = 'auth-info';

export const mutations = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu

  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state, value) {
    state.isVerticalNavMenuActive = value;
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val;
  },
  UPDATE_MAIN_LAYOUT_TYPE(state, val) {
    state.mainLayoutType = val;
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state, val) {
    state.verticalNavMenuItemsMin = val;
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH(state, width) {
    state.verticalNavMenuWidth = width;
  },

  // ////////////////////////////////////////////
  // UI
  // ////////////////////////////////////////////

  TOGGLE_CONTENT_OVERLAY(state, val) { state.bodyOverlay = val; },
  UPDATE_PRIMARY_COLOR(state, val) { state.themePrimaryColor = val; },
  UPDATE_THEME(state, val) { state.theme = val; },
  UPDATE_WINDOW_WIDTH(state, width) { state.windowWidth = width; },
  UPDATE_WINDOW_SCROLL_Y(state, val) { state.scrollY = val; },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  UPDATE_PAGE_TITLE(state, title) {
    state.pageTitle = title;
  },
  ADD_BREADCRUMB_ITEM(state, item) {
    state.breadcrumb.push(item);
  },
  SET_BREADCRUMB(state, items) {
    state.breadcrumb = items;
  },
  UPDATE_BREADCRUMB_ITEM_TITLE(state, { index, title }) {
    state.breadcrumb[index].title = title;
  },
  SET_IS_RETURNING_FROM_BREADCRUMB(state, val) {
    state.isReturningFromBreadcrumb = val;
  },
  INCREASE_FEEDBACK_MODAL_KEY(state) {
    state.feedbackModalKey += 1;
  },

  // /////////////////////////////////////////////
  // Others
  // /////////////////////////////////////////////
  UPDATE_REDIRECT_AFTER_LOGIN(state, path) {
    state.redirectAfterLogin = path;
  },
  REMOVE_REDIRECT_AFTER_LOGIN(state) {
    state.redirectAfterLogin = '';
  },
};
