/**
 * Store audience module.
 *
 * @module store/contacts/contact/module
 * @author Dilan Useche <dilan8810@gmail.com>
 * */

import actions from './contact.actions';

/**
 * export default
 *  @type {VuexModule}
 * */
export default {
  namespaced: true,
  actions,
};
