/**
 * Mutations of the store auth module.
 *
 * @module store/auth/mutations
 * @author Dilan Useche <dilan8810@gmail.com>
 * */
export default {
  /**
   * Set the auth access token info
   *
   * @param {Object} state - vuex state
   * @param {string} token - access token
   * @return {void}
   */
  SET_AUTH_TOKEN(state, token) {
    state.token = token;
  },

  /**
   * Set the auth refresh token info
   *
   * @param {Object} state - vuex state
   * @param {string} token - refresh token
   * @return {void}
   */
  SET_AUTH_REFRESH_TOKEN(state, token) {
    state.refreshToken = token;
  },

  /**
   * Set the value to indicate is refreshing token operation is happen
   *
   * @param {Object} state - vuex state
   * @param {boolean} value - indicate is refreshing token operation is happen
   * @return {void}
   */
  SET_AUTH_IS_REFRESHING_TOKEN(state, value) {
    state.isRefreshingToken = value;
  },

  /**
   * Set the value of refreshing tokens call
   *
   * @param {Object} state - vuex state
   * @param {Promise<any>} call - http call to refresh the tokens
   * @return {void}
   */
  SET_AUTH_REFRESHING_TOKEN_CALL(state, call) {
    state.refreshingTokenCall = call;
  },

  /**
   * Set if user if logged-in
   *
   * @param {Object} state - vuex state
   * @param {boolean} value - logged-in value
   * @return {void}
   */
  SET_AUTH_USER_IS_LOGGED_IN(state, value) {
    state.isUserLoggedIn = value;
  },

  /**
   * Set the auth user info
   *
   * @param {Object} state - vuex state
   * @param {Object} user - auth user info
   * @return {void}
   */
  SET_AUTH_USER(state, user) {
    state.user = user;
  },

  /**
   * Delete the auth state info, making state to null
   *
   * @param {Object} state - vuex state
   * @return {void}
   */
  DELETE_AUTH(state) {
    state.token = null;
    state.refreshToken = null;
    state.user = null;
    state.isUserLoggedIn = false;
  },

  /**
   * Update the auth user partially
   *
   * @param {Object} state - vuex state
   * @param {Object} user - user info to update
   * @return {void}
   */
  PARTIAL_UPDATE_AUTH_USER(state, user) {
    state.user = {
      ...state.user,
      ...user,
    };
  },

  /**
   * Update the auth user tenant partially
   *
   * @param {Object} state - vuex state
   * @param {Object} payload - tenant info to update
   * @return {void}
   */
  PARTIAL_UPDATE_AUTH_USER_TENANT(state, payload) {
    if (state.user
      && state.user.tenant
      && typeof state.user.tenant === 'object'
      && payload
      && typeof payload === 'object') {
      state.user.tenant = {
        ...state.user.tenant,
        ...payload,
      };

      state.user = {
        ...state.user,
      };
    }
  },

  /**
   * Update the auth user tenant account settings reviews
   *
   * @param {Object} state - vuex state
   * @param {Object} payload - tenant info to update
   * @return {void}
   */
  UPDATE_AUTH_USER_TENANT_ACCOUNT_SETTINGS_REVIEWS(state, payload) {
    if (state.user
      && state.user.tenant
      && typeof state.user.tenant === 'object'
      && state.user.tenant.accountSettings
      && state.user.tenant.accountSettings.review
      && typeof state.user.tenant.accountSettings.review === 'object') {
      state.user.tenant.accountSettings.review = {
        ...state.user.tenant.accountSettings.review,
        ...payload,
      };

      state.user = {
        ...state.user,
      };
    }
  },

  /**
   * Update the auth user tenant account settings reviews
   *
   * @param {Object} state - vuex state
   * @param {string[]} markers - markers updated
   * @return {void}
   */
  UPDATE_AUTH_USER_MARKERS(state, markers) {
    if (state.user
      && state.user.markers) {
      state.user.markers = markers;
      state.user = {
        ...state.user,
      };
    }
  },

  /**
   * Update the auth user flags
   *
   * @param {Object} state - vuex state
   * @param {Object} flags - markers updated
   * @return {void}
   */
  UPDATE_AUTH_USER_FLAGS(state, flags) {
    if (state.user && state.user.flags
      && typeof flags === 'object' && Object.keys(flags).length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(flags)) {
        if (state.user.flags[key] !== undefined) {
          state.user.flags[key] = value;
        }
      }

      state.user = {
        ...state.user,
      };
    }
  },

  /**
   * delete campaign draft from user
   *
   * @param {Object} state - vuex state
   * @return {void}
   */
  DELETE_CAMPAIGN_DRAFT(state) {
    state.user.campaignsDraft = null;
    state.user = {
      ...state.user,
    };
  },

  /**
   * Update the auth campaignsDraft user partially
   *
   * @param {Object} state - vuex state
   * @param {Object | null} payload - draft campaign payload
   * @return {void}
   */
  UPDATE_CAMPAIGN_DRAFT_AUTH_USER(state, payload) {
    state.user.campaignsDraft = payload;
    state.user = {
      ...state.user,
    };
  },

  /**
   * Update the auth quick campaignsDraft user partially
   *
   * @param {Object} state - vuex state
   * @param {Object | null} payload - draft campaign payload
   * @return {void}
   */
  UPDATE_QUICK_CAMPAIGN_DRAFT_AUTH_USER(state, payload) {
    state.user.quickCampaignsDraft = payload;
    state.user = {
      ...state.user,
    };
  },

  /**
   * delete campaign draft from user
   *
   * @param {Object} state - vuex state
   * @return {void}
   */
  DELETE_QUICK_CAMPAIGN_DRAFT(state) {
    state.user.quickCampaignsDraft = null;
    state.user = {
      ...state.user,
    };
  },
};
