import { parsePhoneNumber } from 'libphonenumber-js';

export default function (value, international = true) {
  if (!value) {
    return '';
  }

  let phone = value;

  if (value.indexOf('+') < 0) {
    phone = `+${phone}`;
  }

  try {
    const phoneNumber = parsePhoneNumber(phone);

    if (phoneNumber && phoneNumber.isValid()) {
      return international
        ? phoneNumber.formatInternational()
        : phoneNumber.formatNational();
    }
  } catch (e) {
    return '';
  }

  return '';
}
