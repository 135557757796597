/**
 * @typedef SMSCampaignStateModule
 * @type {object}
 * @property {Object} draftCampaign - draft campaign to continue.
 * @property {Object | null} fromSMSTemplate - SMS Template to create a campaign
 * @property {Object | null} payload - Payload to create or edit a campaign
 */

/**
 * State of the store SMS Campaign module.
 *
 * @module store/campaigns/sms-campaign/state
 * @author Dilan Useche <dilan8810@gmail.com>
 * */

/**
 * export default
 *  @type {SMSCampaignStateModule}
 * */
export default {
  draftCampaign: null,
  fromSMSTemplate: null,
  payload: null,
};
