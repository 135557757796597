import grapePerksAppService from '@/api';
import BaseService from '@/api/base.service';
import configApi from '@/api/config';

/**
 * caller id service api
 * @class CallerIdService
 */
class CallerIdService extends BaseService {
  constructor() {
    super({ baseUrl: configApi.baseRoutesUrls.callerIds });
  }

  /**
   * Get available dedicated numbers
   * @function getAvailableDedicatedNumbers
   * @return {Promise.<GrapePerksApiResponse | any>} available dedicated numbers
   */
  getAvailableDedicatedNumbers = () => grapePerksAppService.get(
    `${this.baseUrl}/available-dedicated-numbers`,
  ).then((resp) => resp.data);

  /**
   * init caller id verification, send it a code message
   * @function initVerifyPhone
   * @param {Number} callerId - id of Caller ID to retrieve
   * @return {Promise.<GrapePerksApiResponse>} Caller ID to verify
   */
  initVerifyPhone = ({ callerId }) => grapePerksAppService.get(
    `${this.baseUrl}/start-verification/${callerId}`,
  ).then(
    (resp) => resp.data,
  );

  /**
   * complete verification of caller id checked sent code
   * @function checkVerifyPhone
   * @param {string} callerId - id of Caller ID to retrieve
   * @param {string} code - code to check
   * @return {Promise.<GrapePerksApiResponse>}
   */
  checkVerifyPhone = ({ callerId, code }) => grapePerksAppService.get(`${this.baseUrl}/completion-verification/${callerId}/${code}`)
    .then((resp) => resp.data);

  /**
   * complete verification of caller id checked sent code
   * @function checkVerifyPhone
   * @param {string} callerId - id of Caller ID to retrieve
   * @param {string} code - code to check
   * @param {string} name - name to assign to the caller ID
   * @return {Promise.<GrapePerksApiResponse>}
   */
  completePhoneNumberVerification = ({ callerId, code, name = '' }) => grapePerksAppService.get(`${this.baseUrl}/complete-phone-number-verification/${callerId}/${code}/${name}`)
    .then((resp) => resp.data);

  /**
   * create a caller ID with an international phone number
   * @function initVerifyPhone
   * @param {string} phoneInternational - international phone number to create the caller ID
   * @return {Promise.<GrapePerksApiResponse>} created or existing caller ID
   */
  startPhoneNumberVerification = (phoneInternational) => grapePerksAppService.post(
    `${this.baseUrl}/start-phone-number-verification`, { phoneInternational },
  ).then((resp) => resp.data);

  /**
   * create a caller ID of type dedicated-number
   * @function purchaseDedicatedPhoneNumber
   * @param {Object} payload - payload to create the caller ID
   * @return {Promise.<GrapePerksApiResponse>} created or existing caller ID
   */
  createDedicatedPhoneNumber = (payload) => grapePerksAppService.post(
    `${this.baseUrl}/dedicated-number`, payload,
  ).then((resp) => resp.data);
}

const singletonInstance = new CallerIdService();

/**
 * caller id service api instance
 * @exports api/modules/senders-ids/caller-id
 * */
export default singletonInstance;
