export default function VsConfirmPlugin(Vue) {
  Vue.mixin({
    methods: {
      $showConfirmActionDialog({
        title,
        text,
        acceptText,
        cancelText,
        accept,
        cancel,
      }) {
        this.$showConfirmDialog({
          title,
          text,
          color: 'primary',
          acceptText,
          cancelText,
          accept,
          cancel,
          buttonAccept: 'accept-fill',
          buttonCancel: 'cancel-flat',
        });
      },
      $showConfirmDeleteDialog({
        title,
        text,
        acceptText,
        cancelText,
        accept,
        cancel,
      }) {
        this.$showConfirmDialog({
          title,
          text,
          color: 'danger',
          acceptText,
          cancelText,
          accept,
          cancel,
          buttonAccept: 'accept-fill',
          buttonCancel: 'cancel-flat',
        });
      },
      $showConfirmWarningDialog({
        title,
        text,
        acceptText,
        cancelText,
        accept,
        cancel,
      }) {
        this.$showConfirmDialog({
          title,
          text,
          color: 'warning',
          acceptText,
          cancelText,
          accept,
          cancel,
          buttonAccept: 'accept-fill',
          buttonCancel: 'cancel-flat',
        });
      },
      $showConfirmDialog({
        title,
        text,
        color = '',
        acceptText,
        cancelText,
        accept,
        cancel,
      }) {
        this.$vs.dialog({
          type: 'confirm',
          color,
          title,
          text,
          acceptText,
          cancelText,
          accept,
          cancel,
          buttonAccept: 'accept-fill',
          buttonCancel: 'cancel-flat',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
        });
      },
    },
  });
}
