import tagService from '@/api/modules/tag.service';

/**
 * Actions of the store tag module.
 *
 * Use the tag service to connect to the api.
 * See {@link module:api/modules/audiences/tag}
 * @module store/audiences/tag/actions
 * @author Dilan Useche <dilan8810@gmail.com>
 * */
export default {
  /**
   * Get all tags in the organization that the requesting user has access to
   *
   * Use getAll tag service to fetch the tags.
   * See {@link module:api/modules/audiences/tag~getAll}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} params - query params
   * @return {Promise.<Array.<Object> | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async fetchAllTags({ commit }, params) {
    const resp = await tagService.getAll(params);
    return resp.data;
  },

  /**
   * Get one tag by id
   *
   * Use getOne tag service to fetch the tag
   * See {@link module:api/modules/audiences/tag~getOne}.
   * @param {Object} getters - vuex getters of tag module
   * @param {Object} state - vuex state of tag module
   * @param {string} tagId - id of tag to get
   * @return {Promise.<Object>} tag
   */
  // eslint-disable-next-line no-unused-vars
  async fetchTag({ getters }, tagId) {
    const resp = await tagService.getOne({ id: tagId });
    return resp.data;
  },

  /**
   * Create a new tag.
   *
   * Use create tag service to create the tag.
   * See {@link module:api/modules/audiences/tag~create}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} payload - tag to create
   * @return {Promise.<Array.<Object> | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async addTag({ commit }, payload) {
    const resp = await tagService.create(payload);
    return resp.data;
  },

  /**
   * Import many tags from file.
   *
   * Use importFromFile tag service to import the tags.
   * See {@link module:api/modules/audiences/tag~importFromFile}.
   * @param {Function} commit - vuex store commit function
   * @param {Array<Object>} payloads - tags to import
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async importFromFile({ commit }, payloads) {
    const resp = await tagService.importFromFile(payloads);
    return resp.data;
  },

  /**
   * Export many tags from file.
   *
   * Use exportFile tag service to export the tags.
   * See {@link module:api/modules/audiences/tag~exportFile}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} exportInfo - tags export info
   * @return {Promise<string | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async exportFile({ commit }, exportInfo) {
    const resp = await tagService.exportFile(exportInfo);
    return resp.data;
  },

  /**
   * Update and commit one tag.
   *
   * Use edit tag service to update the tag.
   * See {@link module:api/modules/audiences/tag~edit}.
   * @param {Function} commit - vuex store commit function
   * @param {string} id - id of tag to update
   * @param {Object} tag - tag to update
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async editTag({ commit }, { id, ...tag }) {
    const resp = await tagService.edit({
      id,
      payload: tag,
    });

    return resp.data;
  },

  /**
   * Delete one tag by id
   *
   * Use delete tag service to delete the tag.
   * See {@link module:api/modules/audiences/tag~delete}.
   * @param {Function} commit - vuex store commit function
   * @param {string} tagId - id of tag to delete
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async removeTag({ commit }, tagId) {
    const resp = await tagService.delete(tagId);
    return resp.data;
  },

  /**
   * Delete many tags by id
   *
   * Use deleteMany tag service to delete the tags.
   * See {@link module:api/modules/audiences/tag~deleteMany}.
   * @param {Function} commit - vuex store commit function
   * @param {Array.<Object> | null} data - tags to delete
   * @param {Object} filters - filters to search tags to delete
   * @return {Promise.<any>}
   */
  // eslint-disable-next-line no-unused-vars
  async removeTags({ commit }, { data = null, filters = {} }) {
    const resp = await tagService.deleteMany({
      data: data ? data.map((t) => t.id) : null,
      filters,
    });

    return resp.data;
  },
};
