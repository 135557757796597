/**
 * @typedef CallerIdStateModule
 * @type {object}
 * @property {Object | null} lastCreated - last Caller ID created.
 */

/**
 * State of the store caller id module.
 *
 * @module store/senders-ids/caller-id/state
 * @author Dilan Useche <dilan8810@gmail.com>
 * */

/**
 * export default
 *  @type {CallerIdStateModule}
 * */
export default {
  lastCreated: null,
};
