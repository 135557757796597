import callerIdService from '@/api/modules/caller-id.service';

/**
 * Actions of the store caller id module.
 *
 * Use the caller id service to connect to the api.
 * See {@link module:api/modules/senders-ids/caller-id}
 * @module store/senders-ids/caller-id/actions
 * @author Dilan Useche <dilan8810@gmail.com>
 * */
export default {
  /**
   * Get all caller ids in the organization that the requesting user has access to
   *
   * Use getAll caller id service to fetch the caller ids.
   * See {@link module:api/modules/senders-ids/caller-id~getAll}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} params - query params
   * @return {Promise.<Array.<Object> | any>}
   */
  async fetchAllCallerIds({ commit }, params) {
    const resp = await callerIdService.getAll(params);
    return resp.data;
  },

  /**
   * Get one caller id by id
   *
   * Use getOne caller id service to fetch the caller id
   * See {@link module:api/modules/senders-ids/caller-id~getOne}.
   * @param {Object} getters - vuex getters of caller id module
   * @param {Object} state - vuex state of caller id module
   * @param {string} callerId - id of caller id to get
   * @return {Promise.<Object>} caller id
   */
  // eslint-disable-next-line no-unused-vars
  async fetchCallerId({ getters }, callerId) {
    const resp = await callerIdService.getOne({ id: callerId });
    return resp.data;
  },

  /**
   * Create a new caller id.
   *
   * Use create caller id service to create the caller id.
   * See {@link module:api/modules/senders-ids/caller-id~create}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} caller - caller id to create
   * @return {Promise.<Object | any>}
   */
  async addCallerId({ commit }, caller) {
    const resp = await callerIdService.create(caller);
    commit('SET_CALLER_ID_LAST_CREATED', resp.data);
    return resp.data;
  },

  /**
   * Export many callers ids to file.
   *
   * Use exportFile caller id service to export the callers ids.
   * See {@link module:api/modules/senders-ids/caller-id~exportFile}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} exportInfo - caller id export info
   * @return {Promise<string | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async exportFile({ commit }, exportInfo) {
    const resp = await callerIdService.exportFile(exportInfo);
    return resp.data;
  },

  /**
   * Update and commit one caller id.
   *
   * Use edit caller id service to update the caller id.
   * See {@link module:api/modules/senders-ids/caller-id~edit}.
   * @param {Function} commit - vuex store commit function
   * @param {string} id - id of caller id to update
   * @param {Object} caller - caller id to update
   * @return {Promise.<Object>}
   */
  // eslint-disable-next-line no-unused-vars
  async editCallerId({ commit }, { id, ...caller }) {
    const resp = await callerIdService.edit({
      id,
      payload: caller,
    });
    return resp.data;
  },

  /**
   * Delete one caller id by id
   *
   * Use delete caller id service to delete the caller id.
   * See {@link module:api/modules/senders-ids/caller-id~delete}.
   * @param {Function} commit - vuex store commit function
   * @param {string} callerId - id of caller id to delete
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async removeCallerId({ commit }, callerId) {
    const resp = await callerIdService.delete(callerId);
    return resp.data;
  },

  /**
   * Delete many callers ids by id
   *
   * Use deleteMany caller id service to delete the callers ids.
   * See {@link module:api/modules/senders-ids/caller-id~deleteMany}.
   * @param {Function} commit - vuex store commit function
   * @param {Array.<Object> | null} data - callers ids to delete
   * @param {Object} filters - filters to search callers ids to delete
   * @return {Promise.<any>}
   */
  // eslint-disable-next-line no-unused-vars
  async removeCallerIds({ commit }, { data = null, filters = {} }) {
    const resp = await callerIdService.deleteMany({
      data: data ? data.map((t) => t.id) : null,
      filters,
    });

    return resp.data;
  },

  /**
   * Init verification Caller ID phone
   *
   * Use initVerifyPhone caller id service to verify the Caller ID.
   * See {@link module:api/modules/senders-ids/caller-id~initVerifyPhone}.
   * @param {Function} commit - vuex store commit function
   * @param {number} callerId - id of Caller ID to verify
   * @return {Promise.<boolean>}
   */
  // eslint-disable-next-line no-unused-vars
  async initVerifyPhone({ commit }, { callerId }) {
    return callerIdService.initVerifyPhone({ callerId });
  },

  /**
   * Check verification code of Caller ID phone
   *
   * Use checkVerifyPhoneCode caller id service to verify the Caller ID.
   * See {@link module:api/modules/senders-ids/caller-id~checkVerifyPhone}.
   * @param {Function} commit - vuex store commit function
   * @param {string} callerId - id of Caller ID to verify
   * @param {string} code - code to check
   * @return {Promise.<string | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async checkVerifyPhoneCode({ commit }, { callerId, code }) {
    return callerIdService.checkVerifyPhone({
      callerId,
      code,
    });
  },
};
