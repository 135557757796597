import Vue from 'vue';
import Router from 'vue-router';
import i18n from '@/i18n/i18n';
import store from '../store/store';
import routes from './routes';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

router.afterEach((to, from) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }

  Vue.nextTick(() => {
    const brand = i18n.t('$Brand.Name') || '';
    const title = to.meta.pageTitle ? i18n.tc(
      to.meta.pageTitle,
      to.meta.i18nPageTitle || 2,
    ) || '' : '';
    const sep = title ? ' - ' : '';
    document.title = `${title}${sep}${brand}`;
  });
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (!store.getters['auth/isUserLoggedIn']) {
      if (to.query && to.query.loginRedirect) {
        store.commit('UPDATE_REDIRECT_AFTER_LOGIN', to.query.loginRedirect);
      }

      store.dispatch('resetBreadcrumb');
      next({ name: 'login' });
    } else if (to.matched.some((route) => route.meta && route.meta.roles)) {
      const roleName = store.getters['auth/role'];
      let validPermission = true;

      to.matched.forEach((route) => {
        if ((route.meta && route.meta.roles && !route.meta.roles.includes(roleName))) {
          validPermission = false;
        }
      });

      if (validPermission) {
        if (store.getters['auth/getUserFlag']('acceptTermsAndConditions')) {
          store.dispatch('updateBreadcrumb', { to, from });
          next();
        } else if (!to.matched.some((route) => route.meta.isTermsAndConditions)) {
          next({ name: 'accept-terms-and-conditions' });
        } else {
          next();
        }
      } else {
        next({ name: 'not-authorized' });
      }
    } else if (store.getters['auth/getUserFlag']('acceptTermsAndConditions')) {
      store.dispatch('updateBreadcrumb', { to, from });
      next();
    } else if (!to.matched.some((route) => route.meta.isTermsAndConditions)) {
      next({ name: 'accept-terms-and-conditions' });
    } else {
      next();
    }
  } else if (to.matched.some((route) => route.meta.requiresGuest)) {
    if (store.getters['auth/isUserLoggedIn']) {
      next({ name: 'home' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
