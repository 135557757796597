/**
 * @typedef NotificationStateModule
 * @type {object}
 * @property {Array.<Object>} unreadCollection - last unread notifications
 * @property {number} unseenCount - count of unseen notifications
 */

/**
 * State of the store notification module.
 *
 * @module store/notification/state
 * @author Dilan Useche <dilan8810@gmail.com>
 * */

/**
 * export default
 *  @type {NotificationStateModule}
 * */
export default {
  unseenCount: 0,
  unseenCollection: [],
};
