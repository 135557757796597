export default function VsNotificationPlugin(Vue) {
  Vue.mixin({
    methods: {
      $showAsyncActionNotification({ title, text }) {
        this.$showNotification({
          title,
          text,
          icon: 'icon-clock',
          color: 'primary',
        });
      },
      $showSuccessActionNotification({ title, text }) {
        this.$showNotification({
          title,
          text,
          icon: 'icon-check',
          color: 'success',
        });
      },
      $showErrorActionNotification({ title, text }) {
        this.$showGeneralErrorNotification({
          title,
          text,
        });
      },
      $showGeneralErrorNotification({ title, text }) {
        this.$showNotification({
          title,
          text,
          icon: 'icon-alert-triangle',
          color: 'danger',
        });
      },
      $showGeneralWarningNotification({ title, text }) {
        this.$showNotification({
          title,
          text,
          icon: 'icon-alert-circle',
          color: 'warning',
        });
      },
      $showNotification({
        title,
        text,
        iconPack = 'feather',
        icon = '',
        color = '',
        time = 8000,
      }) {
        this.$vs.notify({
          title,
          text,
          iconPack,
          icon,
          color,
          time,
        });
      },
    },
  });
}
