import enums from '@/enums';
import smsCampaignService from '@/api/modules/campaigns/sms-campaign.service';

/**
 * Actions of the store SMS Campaign module.
 *
 * Use the SMS Campaign service to connect to the api.
 * See {@link module:api/modules/campaigns/sms-campaign}
 * @module store/campaigns/sms-campaign/actions
 * @author Dilan Useche <dilan8810@gmail.com>
 * */
export default {
  /**
   * Get analytics data to chart
   *
   * @param {Object} rootState - vuex store root state
   * @param {Object} params - query params
   * @return {Promise.<Array.<Object> | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async fetchAnalytics({ rootState }, params) {
    const resp = await smsCampaignService.getAnalytics(params);
    return resp.data;
  },

  // eslint-disable-next-line no-unused-vars
  async fetchCountSMSCampaignsFilters({ commit }, params) {
    const resp = await smsCampaignService.getCountWithFilters(params);
    return resp.data;
  },

  /**
   * Get all sms campaigns count
   *
   * @param {Function} commit - vuex store commit function
   * @param {Object} params - query params
   * @return {Promise.<Array.<Object> | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async fetchCountSMSCampaigns({ commit }, params) {
    const resp = await smsCampaignService.getCount(params);
    return resp.data;
  },

  /**
   * Get all SMS Campaign in the organization that the requesting user has access to
   *
   * Use getAll SMS Campaign service to fetch the SMS Campaigns.
   * See {@link module:api/modules/campaigns/sms-campaign~getAll}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} params - query params
   * @return {Promise.<Array.<Object> | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async fetchAllSMSCampaigns({ commit }, params) {
    const resp = await smsCampaignService.getAll(params);
    return resp.data;
  },

  /**
   * Get one SMS Campaign by id
   *
   * Use getOne SMS Campaign service to fetch the SMS Campaign
   * See {@link module:api/modules/campaigns/sms-campaign~getOne}.
   * @param {Object} getters - vuex getters of SMS Campaign module
   * @param {string} smsCampaignId - id of SMS Campaign to get
   * @return {Promise.<Object>} SMS Campaign
   */
  // eslint-disable-next-line no-unused-vars
  async fetchSMSCampaign({ getters }, smsCampaignId) {
    const resp = await smsCampaignService.getOne({ id: smsCampaignId });
    return resp.data;
  },

  /**
   * Get one SMS Campaign details by id
   *
   * Use getOne SMS Campaign service to fetch the SMS Campaign
   * @param {Object} getters - vuex getters of SMS Campaign module
   * @param {string} id - id of SMS Campaign to get details
   * @param {Object} params - params to get details
   * @return {Promise.<Object>} SMS Campaign details
   */
  // eslint-disable-next-line no-unused-vars
  async fetchSMSCampaignDetails({ getters }, { id, params }) {
    const resp = await smsCampaignService.getDetails({
      id,
      params,
    });
    return resp.data;
  },

  /**
   * Get test cost for SMS Campaign.
   *
   * @param {Function} commit - vuex store commit function
   * @param {Object} payload - info to test
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async fetchTestCostSMSCampaign({ rootState }, payload) {
    const resp = await smsCampaignService.getTestCost(payload);
    return resp.data;
  },

  /**
   * Fetch the cost of a SMS Campaign.
   *
   * Use getCost SMS Campaign service to get the cost of the SMS Campaign.
   * See {@link module:api/modules/campaigns/sms-campaign~getCost}.
   * @param {Object} rootState - vuex store root state
   * @param {Object} campaignInfo - campaign info
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async fetchCostSMSCampaign({ rootState }, campaignInfo) {
    const resp = await smsCampaignService.getCost(campaignInfo);
    return resp.data;
  },

  /**
   * Create a new SMS Campaign.
   *
   * Use create SMS Campaign service to create the SMS Campaign.
   * See {@link module:api/modules/campaigns/sms-campaign~create}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} rootState - vuex store root state
   * @param {Object} smsCampaign - SMS Campaign to create
   * @return {Promise.<Object | any | null>}
   */
  // eslint-disable-next-line no-unused-vars
  async addSMSCampaign({ commit, rootState }, smsCampaign) {
    const resp = await smsCampaignService.create(smsCampaign);

    commit('auth/DELETE_CAMPAIGN_DRAFT', null, { root: true });

    return resp.data;
  },

  /**
   * Create a new Quick SMS Campaign.
   *
   * @param {Function} commit - vuex store commit function
   * @param {Object} smsCampaign - SMS Campaign to create
   * @return {Promise.<Object | any | null>}
   */
  // eslint-disable-next-line no-unused-vars
  async addSMSQuickCampaign({ commit }, smsCampaign) {
    const resp = await smsCampaignService.create(smsCampaign);

    commit('auth/DELETE_QUICK_CAMPAIGN_DRAFT', null, { root: true });

    return resp.data;
  },

  /**
   * Export many sms campaigns to file.
   *
   * Use exportFile sms campaign service to export the sms campaigns.
   * See {@link module:api/modules/campaigns/sms-campaign~exportFile}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} exportInfo - sms campaign export info
   * @return {Promise<string | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async exportFile({ commit }, exportInfo) {
    const resp = await smsCampaignService.exportFile(exportInfo);
    return resp.data;
  },

  /**
   * Update and commit one SMS Campaign.
   *
   * Use edit SMS Campaign service to update the SMS Campaign.
   * See {@link module:api/modules/campaigns/sms-campaign~edit}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} rootState - vuex store root state
   * @param {string} id - id of SMS Campaign to update
   * @param {Object} smsCampaign - SMS Campaign to update
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async editSMSCampaign({ commit, rootState }, { id, ...smsCampaign }) {
    const resp = await smsCampaignService.edit({
      id,
      payload: smsCampaign,
    });

    commit('auth/DELETE_CAMPAIGN_DRAFT', null, { root: true });

    return resp.data;
  },

  /**
   * Delete one SMS Campaign by id
   *
   * Use delete SMS Campaign service to delete the SMS Campaign.
   * See {@link module:api/modules/campaigns/sms-campaign~delete}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} rootState - vuex store root state
   * @param {string} smsCampaignId - id of SMS Campaign to delete
   * @return {Promise.<any>}
   */
  // eslint-disable-next-line no-empty-pattern
  async removeSMSCampaign({ commit, rootState }, smsCampaignId) {
    const resp = await smsCampaignService.delete(smsCampaignId);

    if (rootState.auth.user.campaignsDraft
      // eslint-disable-next-line no-underscore-dangle
      && rootState.auth.user.campaignsDraft._id === smsCampaignId) {
      commit('auth/DELETE_CAMPAIGN_DRAFT', null, { root: true });
    }

    return resp.data;
  },

  /**
   * Delete many SMS Campaigns by id
   *
   * Use deleteMany SMS Campaign service to delete the SMS Campaign.
   * See {@link module:api/modules/campaigns/sms-campaign~deleteMany}.
   * @param {Function} commit - vuex store commit function
   * @param {Array.<Object> | null} data - sms campaigns to delete
   * @param {Object} filters - filters to search sms campaigns to delete
   * @return {Promise.<any>}
   */
  // eslint-disable-next-line no-unused-vars
  async removeSMSCampaigns({ commit }, { data = null, filters = {} }) {
    const resp = await smsCampaignService.deleteMany({
      data: data ? data.map((t) => t.id) : null,
      filters,
    });
    return resp.data;
  },

  /**
   * Test a SMS Campaign.
   *
   * @param {Object} rootState - vuex store root state
   * @param {Object} payload - info to test
   * @return {Promise.<any>}
   */
  // eslint-disable-next-line no-unused-vars
  async testSMSCampaign({ rootState }, payload) {
    const resp = await smsCampaignService.test(payload);
    return resp.data;
  },
};
