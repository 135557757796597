import Vue from 'vue';
import Vuesax from 'vuesax';
import VueMq from 'vue-mq';
import VueClipboard from 'vue-clipboard2';
import VueTour from 'vue-tour';
import VeeValidate from 'vee-validate';
import * as GmapVue from 'gmap-vue';
import { VueHammer } from 'vue2-hammer';
import VEmojiPicker from 'v-emoji-picker';
import AudioRecorder from 'vue-audio-recorder';
import vueCountryRegionSelect from 'vue-country-region-select';
import VueElementLoading from 'vue-element-loading';
import Croppa from 'vue-croppa';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import App from './App.vue';

// Vuesax Component Framework
import 'material-icons/iconfont/material-icons.css'; // Material Icons
import 'vuesax/dist/vuesax.css';

// Grape Perks Service
import grapePerksAppService from './api';

// Theme Configurations
import '../themeConfig';

// Globally Registered Components
import './globalComponents';

// Styles: SCSS
import './assets/scss/main.scss';

// Vue Router
import router from './router';

// Vuex Store
import store from './store/store';

// i18n
import i18n from './i18n/i18n';

// Vuexy Admin Filters
import './filters';

// Vuexy Admin directives
import './directives';

// Custom validators
import './validators';

// Plugins
import enumsPlugin from './plugins/enums.plugin';
import regexPlugin from './plugins/regex.plugin';
import VueElementLoadingPlugin from './plugins/vue-element-loading.plugin';
import VsNotificationPlugin from './plugins/vs-notification.plugin';
import VsConfirmPlugin from './plugins/vs-confirm.plugin';
import VsAlertPlugin from './plugins/vs-alert.plugin';

// PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';

// vue-tour styles
require('vue-tour/dist/vue-tour.css');

// Feather font icon
require('./assets/css/iconfont.css');

const VueScrollTo = require('vue-scrollto');

// prototypes
Vue.prototype.$gpas = grapePerksAppService;

// Plugins
Vue.use(Vuesax);
Vue.use(VueClipboard);
Vue.use(VueTour);
Vue.use(VeeValidate, {
  fieldsBagName: 'validationFields',
});
Vue.use(VueHammer);
Vue.use(AudioRecorder);
Vue.use(vueCountryRegionSelect);
Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_PLATFORM_API_KEY || '',
    libraries: 'places',
  },
});
Vue.component('VueElementLoading', VueElementLoading);
Vue.component('VueImageCropper', Croppa.component);
Vue.use(enumsPlugin);
Vue.use(regexPlugin);
Vue.use(VueElementLoadingPlugin);
Vue.use(VsNotificationPlugin);
Vue.use(VsConfirmPlugin);
Vue.use(VsAlertPlugin);
Vue.use(VueScrollTo);
Vue.use(VEmojiPicker);

Vue.use(VueMq, {
  breakpoints: {
    mobile: 768,
    tablet: 992,
    laptop: 1200,
    desktop: Infinity,
  },
});

Vue.config.productionTip = false;

Sentry.init({
  Vue,
  environment: process.env.NODE_ENV || 'development',
  dsn: process.env.VUE_APP_SENTRY_DNS,
  logErrors: true,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'grapesend.io', /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  beforeSend: (event, hint) => {
    if (hint.originalException
      && hint.originalException.isGrapesendApiError) {
      return null;
    }

    return event;
  },
});

const vm = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}).$mount('#app');

grapePerksAppService.createResponseInterceptor(vm, store);

console.log('environment', process.env.NODE_ENV);
