const Auth = {
  Role: {
    ADMIN: 'admin',
    MERCHANT: 'merchant',
  },
};

const Entity = {
  USER: 'User',
  AUDIENCE: 'Audience',
  SMS_TEMPLATE: 'SMSTemplate',
  MMS_TEMPLATE: 'MMSTemplate',
  RECORDING: 'Recording',
  EMAIL_TEMPLATE: 'EmailTemplate',
  CONTACT_GROUP: 'ContactGroup',
  CONTACT: 'Contact',
  CONTACT_ATTRIBUTE: 'ContactAttribute',
  TAG: 'Tag',
  SEGMENT: 'Segment',
  SMS_CAMPAIGN: 'SMSCampaign',
  MMS_CAMPAIGN: 'MMSCampaign',
  VOICE_CAMPAIGN: 'VoiceCampaign',
  EMAIL_CAMPAIGN: 'EmailCampaign',
  SMS_INBOUND: 'SMSInbound',
  PLAN: 'Plan',
  CALLER_ID: 'CallerID',
  MAILER_ID: 'MailerID',
  APP_SETTINGS: 'AppSettings',
  APP_SERVICE: 'AppService',
};

const Operation = {
  LIST: 'list',
  VIEW: 'view',
  CREATE: 'create',
  EDIT: 'edit',
  CLONE: 'clone',
  DELETE: 'delete',
  EXPORT: 'export',
  RESEND: 'resend',
};

const SharedPermission = { // todo: remove
  NONE: 'none',
  VIEW: 'view',
  MODIFY: 'modify',
  ALL: 'all',
};

const Campaign = {
  Type: {
    SMS: 'sms',
    MMS: 'mms',
    VOICEMAIL: 'voice',
    EMAIL: 'email',
  },
  MessageType: {
    TRANSACTIONAL: 'transactional',
    MARKETING: 'marketing',
    REVIEW: 'review',
  },
  Status: {
    PENDING: 'pending',
    DRAFT: 'draft',
    RUNNING: 'running',
    COMPLETED: 'completed',
    CANCELED: 'canceled',
  },
  CancellationReason: {
    MANUAL_CANCELLATION: 'manual-cancellation',
    INSUFFICIENT_FUNDS: 'insufficient-funds',
  },
  DetailStatus: {
    PENDING: 'pending',
    BOUNCED: 'bounced',
    OUTBOUND: 'outbound',
  },
  DeliveryType: {
    IMMEDIATELY: 'immediately',
    LATER: 'later',
  },
  ListType: {
    History: 'history',
    Drafts: 'drafts',
    Scheduled: 'scheduled',
  },
  SpecialPlaceholders: {
    REVIEW_LINK: 'reviewLink',
  },
};

const Sender = {
  Status: {
    VERIFIED: 'verified',
    UNVERIFIED: 'unverified',
  },
  Caller: {
    Type: {
      SHARED_NUMBER: 'shared-number',
      DEDICATED_NUMBER: 'dedicated-number',
      PHONE_NUMBER: 'phone-number',
      BUSINESS_NAME: 'business-name',
    },
    DedicatedNumberStatus: {
      PROCESSING_PAYMENT: 'processing-payment',
      ACTIVE: 'active',
      MISSING_PAYMENT: 'missing-payment',
      EXPIRED_PAYMENT: 'expired-payment',
      CANCELED: 'canceled',
    },
  },
};

const DataModel = {
  SERVER: 'server',
  CLIENT: 'client',
};

const AppNotifications = {
  Type: {
    REVIEW_CONFIGURATION: 'review-configuration',
    EXPORT_SUCCESS: 'export-success',
    INBOUND_SMS: 'inbound-sms',
    OPT_OUT: 'opt-out',
    OPT_IN: 'opt-in',
    PREPAID_PACKAGE_PAYMENT_SUCCEED: 'prepaid-package-payment-succeed',
    PREPAID_PACKAGE_PAYMENT_FAILED: 'prepaid-package-payment-failed',
    DEDICATED_NUMBER_PAYMENT_SUCCEED: 'dedicated-number-payment-succeed',
    DEDICATED_NUMBER_PAYMENT_FAILED: 'dedicated-number-payment-failed',
    DEDICATED_NUMBER_SUBSCRIPTION_ACTIVE: 'dedicated-number-subscription-active',
    DEDICATED_NUMBER_SUBSCRIPTION_EXPIRED: 'dedicated-number-subscription-expired',
    DEDICATED_NUMBER_SUBSCRIPTION_PAST_DUE: 'dedicated-number-subscription-past-due',
    DEDICATED_NUMBER_SUBSCRIPTION_CANCELED: 'dedicated-number-subscription-canceled',
    CAMPAIGN_COMPLETED: 'campaign-completed',
    CAMPAIGN_CANCELED: 'campaign-canceled',
    AUTO_TOP_UP_ACTIVATED: 'auto-top-up-activated',
  },
};

const SocketEvents = {
  NOTIFICATION: 'notification',
  UPDATE_BALANCE: 'updated-balance',
  SMS_CAMPAIGN_UPDATED: 'sms-campaign-updated',
  CALLER_ID_UPDATED: 'caller-id-updated',
  TENANT_UPDATED: 'tenant-updated',
};

const ImportCollections = {
  Validations: {
    PHONE: 'phone',
  },
};

const Users = {
  Status: {
    INVITED: 'invited',
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    EMAIL_NOT_VERIFIED: 'email-not-verified',
  },
};

const Tenants = {
  APP_SERVICE_ITEM_STATUS: {
    INCOMPLETE: 'incomplete',
    INCOMPLETE_EXPIRED: 'incomplete-expired',
    ACTIVE: 'active',
    PAST_DUE: 'past-due',
    EXPIRED: 'expired',
    CANCELED: 'canceled',
  },
  APP_SERVICE_ITEM_BILLING_INTERVAL: {
    MONTH: 'month',
    TRIMESTER: 'trimester',
    SEMESTER: 'semester',
    YEAR: 'year',
  },
};

const Contact = {
  MarketingStatus: {
    SUBSCRIBED: 'subscribed',
    UNSUBSCRIBED: 'unsubscribed',
  },
};

const StripeEnums = {
  PaymentIntent: {
    Status: {
      REQUIRES_PAYMENT_METHOD: 'requires_payment_method',
      REQUIRES_CONFIRMATION: 'requires_confirmation',
      REQUIRES_ACTION: 'requires_action',
      PROCESSING: 'processing',
      REQUIRES_CAPTURE: 'requires_capture',
      CANCELED: 'canceled',
      SUCCEEDED: 'succeeded',
    },
  },
  Invoice: {
    Status: {
      DRAFT: 'draft',
      OPEN: 'open',
      PAID: 'paid',
      UNCOLLECTIBLE: 'uncollectible',
      VOID: 'void',
    },
  },
  Checkout: {
    Mode: {
      PAYMENT: 'payment',
      SETUP: 'setup',
      SUBSCRIPTION: 'subscription',
    },
  },
  TaxId: {
    VerificationStatus: {
      PENDING: 'pending',
      VERIFIED: 'verified',
      UNVERIFIED: 'unverified',
      UNAVAILABLE: 'unavailable',
    },
  },
  CardBrands: {
    AMERICAN_EXPRESS: 'amex',
    DINERS_CLUB: 'diners',
    DISCOVER: 'discover',
    MASTER_CARD: 'mastercard',
    JCB: 'jcb',
    UNION_PAY: 'unionpay',
    VISA: 'visa',
    UNKNOWN: 'unknown',
  },
};

const PeriodsOfTime = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
};

const AppServices = {
  DefaultServices: {
    SMS: 'sms',
    VOICE: 'voice',
    EMAIL: 'email',
    DNC: 'dnc',
    DEDICATED_NUMBER: 'dedicated-number',
  },
  PackageType: {
    PREPAID: 'prepaid',
    MONTHLY: 'monthly',
  },
};

const AppFilterType = {
  TEXT: 'text',
  NUMBER: 'number',
  DATE: 'date',
  BOOLEAN: 'boolean',
  CATEGORIES: 'categories',
  ENUM: 'enum',
  TAGS: 'tags',
  CONDITIONAL: 'conditional',
};

const AppFilterMathType = {
  ALL: 'all',
  ANY: 'any',
};

const AppFilterOperator = {
  AND: 'AND',
  OR: 'OR',
};

const AppFilterOperation = {
  CONTAINS: 'contains',
  NOT_CONTAINS: 'notContains',
  EQUALS: 'equals',
  NOT_EQUAL: 'notEqual',
  STARTS_WITH: 'startsWith',
  ENDS_WITH: 'endsWith',
  LESS_THAN: 'lessThan',
  LESS_THAN_OR_EQUAL: 'lessThanOrEqual',
  GREATER_THAN: 'greaterThan',
  GREATER_THAN_OR_EQUAL: 'greaterThanOrEqual',
  IN_RANGE: 'inRange',
  ONE: 'one',
  ALL: 'all',
  NONE: 'none',
  NONE_ANY: 'none-any',
};

const AppFilter = {
  FilterType: {
    Text: {
      NAME: AppFilterType.TEXT,
      Type: {
        CONTAINS: AppFilterOperation.CONTAINS,
        NOT_CONTAINS: AppFilterOperation.NOT_CONTAINS,
        EQUALS: AppFilterOperation.EQUALS,
        NOT_EQUAL: AppFilterOperation.NOT_EQUAL,
        STARTS_WITH: AppFilterOperation.STARTS_WITH,
        ENDS_WITH: AppFilterOperation.ENDS_WITH,
      },
    },
    Number: {
      NAME: AppFilterType.NUMBER,
      Type: {
        EQUALS: AppFilterOperation.EQUALS,
        NOT_EQUAL: AppFilterOperation.NOT_EQUAL,
        LESS_THAN: AppFilterOperation.LESS_THAN,
        LESS_THAN_OR_EQUAL: AppFilterOperation.LESS_THAN_OR_EQUAL,
        GREATER_THAN: AppFilterOperation.GREATER_THAN,
        GREATER_THAN_OR_EQUAL: AppFilterOperation.GREATER_THAN_OR_EQUAL,
        IN_RANGE: AppFilterOperation.IN_RANGE,
      },
    },
    Date: {
      NAME: AppFilterType.DATE,
      Type: {
        EQUALS: AppFilterOperation.EQUALS,
        NOT_EQUAL: AppFilterOperation.NOT_EQUAL,
        LESS_THAN: AppFilterOperation.LESS_THAN,
        GREATER_THAN: AppFilterOperation.GREATER_THAN,
        IN_RANGE: AppFilterOperation.IN_RANGE,
      },
    },
    Boolean: {
      NAME: AppFilterType.BOOLEAN,
      Values: {
        UNDETERMINED: 'undetermined',
        YES: 'yes',
        NO: 'no',
      },
    },
    Categories: {
      NAME: AppFilterType.CATEGORIES,
      Type: {
        ONE: AppFilterOperation.ONE,
        ALL: AppFilterOperation.ALL,
        NONE: AppFilterOperation.NONE,
        NONE_ANY: AppFilterOperation.NONE_ANY,
      },
    },
    Enum: {
      NAME: AppFilterType.ENUM,
      Type: {
        ONE: AppFilterOperation.ONE,
        ALL: AppFilterOperation.ALL,
        NONE: AppFilterOperation.NONE,
        NONE_ANY: AppFilterOperation.NONE_ANY,
      },
    },
    Tags: {
      NAME: AppFilterType.TAGS,
      Type: {
        ONE: AppFilterOperation.ONE,
        ALL: AppFilterOperation.ALL,
        NONE: AppFilterOperation.NONE,
        NONE_ANY: AppFilterOperation.NONE_ANY,
      },
    },
  },
  FilterMathType: AppFilterMathType,
  FilterOperator: AppFilterOperator,
};

const AppSortBy = {
  ASC: 'asc',
  DESC: 'desc',
};

const AppFeedbackType = {
  NO_COMMENTS: 'no-comments',
  SUGGEST_IMPROVEMENT: 'suggest-improvement',
  GIVE_US_COMPLIMENT: 'give-us-compliment',
  EXPERIENCED_PROBLEM: 'experienced-problem',
  NOT_FIND_SOMETHING: 'not-find-something',
};

const Attributes = {
  Type: {
    TEXT: AppFilterType.TEXT,
    DATE: AppFilterType.DATE,
    NUMBER: AppFilterType.NUMBER,
    BOOL: AppFilterType.BOOLEAN,
    CATEGORY: AppFilterType.CATEGORIES,
    DEFAULT: 'default',
    SPECIAL: 'special',
  },
};

const mqBreakpoints = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  LAPTOP: 'laptop',
  DESKTOP: 'desktop',
};

const AppLinks = {
  TERMS_AND_CONDITIONS: 'https://grapesend.com.au',
};

const DEFAULT_ALL_CONTACTS_SEGMENT_ID = 'defaultAllContactsOption';

Object.freeze(Auth);
Object.freeze(Entity);
Object.freeze(Operation);
Object.freeze(SharedPermission);
Object.freeze(Campaign);
Object.freeze(Sender);
Object.freeze(DataModel);
Object.freeze(AppNotifications);
Object.freeze(SocketEvents);
Object.freeze(ImportCollections);
Object.freeze(Attributes);
Object.freeze(Users);
Object.freeze(Tenants);
Object.freeze(StripeEnums);
Object.freeze(PeriodsOfTime);
Object.freeze(AppServices);
Object.freeze(Contact);
Object.freeze(AppFilterType);
Object.freeze(AppFilterMathType);
Object.freeze(AppFilterOperator);
Object.freeze(AppFilterOperation);
Object.freeze(AppFilter);
Object.freeze(AppSortBy);
Object.freeze(AppFeedbackType);
Object.freeze(mqBreakpoints);
Object.freeze(AppLinks);

export default {
  Auth,
  Entity,
  Operation,
  SharedPermission,
  Campaign,
  Sender,
  DataModel,
  AppNotifications,
  SocketEvents,
  ImportCollections,
  Attributes,
  Users,
  Tenants,
  StripeEnums,
  PeriodsOfTime,
  AppServices,
  Contact,
  AppFilterType,
  AppFilterMathType,
  AppFilterOperator,
  AppFilterOperation,
  AppFilter,
  AppSortBy,
  AppFeedbackType,
  mqBreakpoints,
  AppLinks,
  DEFAULT_ALL_CONTACTS_SEGMENT_ID,
};
