import { validateSenderBusinessName } from '@/util/util.validate';
import { nonAlphaNumericRegex } from '@/util/util.regex';
import capitalizeFilter from '@/filters/capitalize.filter';

export default function (value) {
  if (!value) return '';

  if (validateSenderBusinessName(value)) {
    return value;
  }

  return capitalizeFilter(value).replace(nonAlphaNumericRegex, '').substr(0, 11);
}
