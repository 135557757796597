import moment from 'moment';
import 'moment-timezone';

export default function (value, format = 'DD-MM-YYYY', utc = false, timezone = '') {
  if (!value) return '';

  if (utc) {
    if (timezone) {
      return moment.utc(value).tz(timezone).format(format);
    }

    return moment.utc(value).format(format);
  }

  if (timezone) {
    return moment(value).tz(timezone).format(format);
  }

  return moment(value).format(format);
}
