/**
 * @typedef AuthStateModule
 * @type {object}
 * @property {string | null} token - access api token
 * @property {string | null} refreshToken - refresh api token
 * @property {bool} isRefreshingToken - indicate if the operation of refresh token is happened
 * @property {Promise<any> | null} refreshingTokenCall - refreshing token http call
 * @property {boolean | null} isUserLoggedIn - indicate if the user is logged-in
 * @property {Object} user - auth user info
 */

/**
 * State of the store auth module.
 *
 * @module store/auth/state
 * @author Dilan Useche <dilan8810@gmail.com>
 * */

export const STORAGE_AUTH_USER_KEY = 'auth-user';
export const STORAGE_AUTH_LOGGED_IN_KEY = 'auth-user-logged-in';

/**
 * export default
 *  @type {AuthStateModule}
 * */
export const state = {
  token: null,
  refreshToken: null,
  isRefreshingToken: false,
  refreshingTokenCall: null,
  isUserLoggedIn: JSON.parse(window.localStorage.getItem(STORAGE_AUTH_LOGGED_IN_KEY) || false),
  user: JSON.parse(window.localStorage.getItem(STORAGE_AUTH_USER_KEY) || null),
};
