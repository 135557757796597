import { findByKey } from '@/util';

/**
 * Getters of the store notification module.
 *
 * @module store/notification/getters
 * @author Dilan Useche <dilan8810@gmail.com>
 * */
export default {
  /**
   * Find and return an notification by id
   *
   * @param {Object} state - state of module
   * @param {number} notificationId - id of notification to find
   * @return {Object|undefined} notification if found, otherwise undefined
   */
  getNotification: (state) => (notificationId) => findByKey(state.unreadCollection, 'id', notificationId),
};
