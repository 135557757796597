import { emailRegex, senderBusinessNameRegex } from './util.regex';

/**
 * Validate if an email is a valid email
 *
 * @param {string} email - email to validate
 * @return {boolean}
 */
export function validateEmail(email) {
  return (emailRegex.test(email));
}

/**
 * Validate if an business name is a valid sender
 *
 * @param {string} businessName - business name to validate
 * @return {boolean}
 */
export function validateSenderBusinessName(businessName) {
  return (senderBusinessNameRegex.test(businessName));
}
