/**
 * Store notification module.
 *
 * @module store/notification/module
 * @author Dilan Useche <dilan8810@gmail.com>
 * */

import state from './notification.state';
import mutations from './notification.mutations';
import actions from './notification.actions';
import getters from './notification.getters';

/**
 * export default
 *  @type {VuexModule}
 * */
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
