export default function (value) {
  if (!value) return '';

  value = value.toString();
  const arr = value.split(' ');
  const capitalizedArray = [];

  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalizedArray.push(capitalized);
  });

  return capitalizedArray.join(' ');
}
