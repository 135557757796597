import configApi from '@/api/config';
import BaseCampaignService from '@/api/modules/campaigns/base-campaign.service';

/**
 * sms campaign service api
 * @class SmsCampaignService
 */
class SmsCampaignService extends BaseCampaignService {
  constructor() {
    super({ baseUrl: configApi.baseRoutesUrls.smsCampaigns });
  }
}

const singletonInstance = new SmsCampaignService();

/**
 * sms campaign service api instance
 * @exports api/modules/campaigns/sms-campaign
 * */
export default singletonInstance;
