import enums from '@/enums';

const routes = [
  {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
    path: '',
    component: () => import('../layouts/main/Main.vue'),
    name: 'main-layout',
    meta: {
      requiresAuth: true,
    },
    redirect: '/home',
    children: [
      // =============================================================================
      // Theme Routes
      // =============================================================================
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/TheHome.vue'),
        meta: {
          pageTitle: '$General.Home',
        },
      },
      {
        path: '/analytics/campaigns',
        name: 'campaigns-analytics',
        component: () => import('../views/analytics/CampaignsAnalytics.vue'),
        meta: {
          pageTitle: '$PageTitle.CampaignsAnalytics',
        },
      },
      {
        path: '/analytics/contacts',
        name: 'contacts-analytics',
        component: () => import('../views/analytics/ContactsAnalytics.vue'),
        meta: {
          pageTitle: '$PageTitle.ContactsAnalytics',
        },
      },
      {
        path: '/analytics/reviews',
        name: 'reviews-analytics',
        component: () => import('../views/analytics/ReviewsAnalytics.vue'),
        meta: {
          pageTitle: '$PageTitle.ReviewsAnalytics',
        },
      },
      {
        path: '/analytics/feedback',
        name: 'feedback-analytics',
        component: () => import('../views/analytics/FeedbackAnalytics/FeedbackAnalytics.vue'),
        props: {
          hideActionsColumn: true,
          hideUpdatedAtColumn: true,
          createdAtHeaderName: '$General.DateAndTime',
        },
        meta: {
          pageTitle: '$PageTitle.FeedbackAnalytics',
          roles: [enums.Auth.Role.ADMIN],
        },
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: () => import('../views/pages/Notifications.vue'),
        meta: {
          pageTitle: '$General.Notification',
        },
      },

      // =============================================================================
      // Modules Routes
      // =============================================================================
      // Merchant Module
      {
        path: '/merchants',
        name: 'merchants',
        component: () => import('@/views/modules/merchant/MerchantList.vue'),
        meta: {
          pageTitle: '$Entities.Merchant',
          operation: enums.Operation.LIST,
          roles: [enums.Auth.Role.ADMIN],
        },
      },

      // App Settings Module
      {
        path: '/app-settings',
        name: 'app-settings',
        component: () => import('@/views/modules/app-settings/TheAppSettings.vue'),
        meta: {
          pageTitle: '$Entities.AppSettings',
          operation: enums.Operation.LIST,
          roles: [enums.Auth.Role.ADMIN],
        },
      },

      // App Services
      {
        path: '/app-services',
        name: 'app-services',
        component: () => import('@/views/modules/app-service/AppServiceList.vue'),
        meta: {
          pageTitle: '$Entities.AppService',
          operation: enums.Operation.LIST,
          roles: [enums.Auth.Role.ADMIN],
        },
      },

      // SMS Campaigns Module
      {
        path: '/sms-campaigns/history',
        name: 'sms-campaigns-history',
        component: () => import('@/views/modules/campaigns/sms-campaign/SMSCampaignList.vue'),
        props: {
          statusFilter: {
            filterType: 'enum',
            type: 'none',
            filter: [enums.Campaign.Status.DRAFT, enums.Campaign.Status.PENDING],
          },
          hideUpdatedAtColumn: true,
          listType: enums.Campaign.ListType.History,
        },
        meta: {
          pageTitle: '$SMSCampaignModule.SMSHistory',
          operation: enums.Operation.LIST,
        },
      },
      {
        path: '/sms-campaigns/drafts',
        name: 'sms-campaigns-drafts',
        component: () => import('@/views/modules/campaigns/sms-campaign/SMSCampaignList.vue'),
        props: {
          statusFilter: {
            filterType: 'text',
            type: 'equals',
            filter: enums.Campaign.Status.DRAFT,
          },
          listType: enums.Campaign.ListType.Drafts,
        },
        meta: {
          pageTitle: '$SMSCampaignModule.SMSDraft',
          operation: enums.Operation.LIST,
        },
      },
      {
        path: '/sms-campaigns/scheduled',
        name: 'sms-campaigns-scheduled',
        component: () => import('@/views/modules/campaigns/sms-campaign/SMSCampaignList.vue'),
        props: {
          statusFilter: {
            filterType: 'text',
            type: 'equals',
            filter: enums.Campaign.Status.PENDING,
          },
          listType: enums.Campaign.ListType.Scheduled,
        },
        meta: {
          pageTitle: '$SMSCampaignModule.SMSScheduled',
          operation: enums.Operation.LIST,
        },
      },

      {
        path: '/sms-campaigns/create',
        name: 'sms-campaigns-create',
        component: () => import('@/views/modules/campaigns/sms-campaign/SMSCampaignListCreateOrEdit.vue'),
        props: {
          entity: enums.Entity.SMS_CAMPAIGN,
          operation: enums.Operation.CREATE,
        },
        meta: {
          pageTitle: '$SMSCampaignModule.CreateAnSMSCampaign',
          operation: enums.Operation.CREATE,
        },
      },

      {
        path: '/sms-campaigns/resend',
        name: 'sms-campaigns-resend',
        component: () => import('@/views/modules/campaigns/sms-campaign/SMSCampaignListCreateOrEdit.vue'),
        props: {
          entity: enums.Entity.SMS_CAMPAIGN,
          operation: enums.Operation.CLONE,
        },
        meta: {
          pageTitle: '$SMSCampaignModule.ResendAnSMSCampaign',
          operation: enums.Operation.CLONE,
        },
      },

      {
        path: '/sms-campaigns/create-from-contacts',
        name: 'sms-campaigns-create-contacts',
        component: () => import('@/views/modules/campaigns/sms-campaign/SMSCampaignListCreateOrEdit.vue'),
        props: true,
        meta: {
          pageTitle: '$SMSCampaignModule.CreateAnSMSCampaign',
          operation: enums.Operation.CREATE,
        },
      },

      {
        path: '/sms-campaigns/edit',
        name: 'sms-campaigns-edit',
        component: () => import('@/views/modules/campaigns/sms-campaign/SMSCampaignListCreateOrEdit.vue'),
        props: {
          entity: enums.Entity.SMS_CAMPAIGN,
          operation: enums.Operation.EDIT,
        },
        meta: {
          pageTitle: '$SMSCampaignModule.EditSMSCampaign',
          operation: enums.Operation.EDIT,
        },
      },
      {
        path: '/sms-campaigns/quick',
        name: 'sms-campaigns-quick',
        component: () => import('@/views/modules/campaigns/sms-campaign/SMSCampaignListQuickCreateOrEdit.vue'),
        props: {
          entity: enums.Entity.SMS_CAMPAIGN,
          operation: enums.Operation.CREATE,
        },
        meta: {
          pageTitle: '$SMSCampaignModule.QuickSMSCampaignPageTitle',
          operation: enums.Operation.CREATE,
        },
      },
      {
        path: '/sms-campaigns/quick/resend',
        name: 'sms-campaigns-quick-resend',
        component: () => import('@/views/modules/campaigns/sms-campaign/SMSCampaignListQuickCreateOrEdit.vue'),
        props: {
          entity: enums.Entity.SMS_CAMPAIGN,
          operation: enums.Operation.CLONE,
        },
        meta: {
          pageTitle: '$SMSCampaignModule.ResendQuickSMSCampaignPageTitle',
          operation: enums.Operation.CLONE,
        },
      },

      // SMS Inbounds Module
      {
        path: '/sms-inbounds',
        name: 'sms-inbounds',
        component: () => import('@/views/modules/inbound-sms/InboundSMSList.vue'),
        props: {
          hideUpdatedAtColumn: true,
          createdAtHeaderName: '$General.DateAndTime',
        },
        meta: {
          pageTitle: '$Entities.SMSInbound',
          operation: enums.Operation.LIST,

        },
      },

      // Reviews Module
      {
        path: '/reviews',
        name: 'reviews',
        component: () => import('@/views/modules/reviews/ReviewsSettings.vue'),
        meta: {
          pageTitle: '$General.Review',
          operation: enums.Operation.LIST,

        },
      },

      {
        path: '/custom-fields',
        name: 'custom-fields',
        component: () => import('@/views/modules/attribute/AttributeList.vue'),
        meta: {
          pageTitle: '$Entities.ContactAttribute',
          operation: enums.Operation.LIST,

        },
      },

      {
        path: '/tags',
        name: 'tags',
        component: () => import('@/views/modules/tags/TagList.vue'),
        meta: {
          pageTitle: '$Entities.Tag',
          operation: enums.Operation.LIST,

        },
      },

      // Contacts Module
      {
        path: '/contacts',
        name: 'contacts',
        component: () => import('@/views/modules/contacts/ContactList.vue'),
        meta: {
          pageTitle: '$Entities.Contact',
          operation: enums.Operation.LIST,

        },
      },

      {
        path: '/contacts/trash',
        name: 'contacts-trash',
        component: () => import('@/views/modules/contacts/ContactList.vue'),
        props: {
          trash: true,
        },
        meta: {
          pageTitle: 'Deleted contacts',
          operation: enums.Operation.LIST,

        },
      },
      {
        path: '/segments',
        name: 'segments',
        component: () => import('@/views/modules/segment/SegmentList.vue'),
        meta: {
          pageTitle: '$Entities.Segment',
          operation: enums.Operation.LIST,

        },
      },

      // SMS Templates Module
      {
        path: '/sms-templates-tab',
        name: 'sms-templates-tab',
        component: () => import('@/views/modules/templates/sms-template/SMSTemplateTest.vue'),
        meta: {
          pageTitle: '$Entities.SMSTemplate',
          operation: enums.Operation.LIST,

        },
      },
      {
        path: '/sms-templates',
        name: 'sms-templates',
        component: () => import('@/views/modules/templates/sms-template/SMSTemplateList.vue'),
        props: { actionsMinWidth: 200 },
        meta: {
          pageTitle: '$Entities.SMSTemplate',
          operation: enums.Operation.LIST,

        },
      },
      {
        path: '/sms-templates/trash',
        name: 'sms-templates-trash',
        component: () => import('@/views/modules/templates/sms-template/SMSTemplateList.vue'),
        props: { trash: true, actionsMinWidth: 150 },
        meta: {
          pageTitle: 'Deleted SMS templates',
          operation: enums.Operation.LIST,

        },
      },

      // Caller Ids Module
      {
        path: '/caller-ids',
        name: 'caller-ids',
        component: () => import('@/views/modules/sender-ids/caller-id/CallerIdList.vue'),
        meta: {
          pageTitle: '$Entities.CallerID',
          operation: enums.Operation.LIST,

        },
      },

      // =============================================================================
      // User settings responsive menu
      // =============================================================================
      {
        path: '/settings',
        alias: [
          '/account/settings',
        ],
        name: 'user-settings',
        component: () => import('@/views/modules/user/user-settings-menu/UserSettings.vue'),
        meta: {
          pageTitle: '$General.Setting',
          i18nPageTitle: 1,
        },
      },
      {
        path: '/account-mobile/settings',
        name: 'user-account-settings',
        component: () => import('@/views/modules/user/user-settings-menu/UserSettings.vue'),
        meta: {
          pageTitle: '$General.Setting',
        },
      },

      // =============================================================================
      // User account module
      // =============================================================================
      {
        path: '/account',
        alias: [
          '/account/profile',
          '/account/details',
          '/account/security',
        ],
        name: 'user-account',
        component: () => import('@/views/modules/user/user-account/UserAccount.vue'),
        meta: {
          pageTitle: '$General.Account',
          i18nPageTitle: 1,
        },
      },
      {
        path: '/account-mobile/profile',
        name: 'user-account-profile',
        component: () => import('@/views/modules/user/user-account/user-account-profile/UserAccountProfile.vue'),
        meta: {
          pageTitle: '$General.Profile',
        },
      },
      {
        path: '/account-mobile/details',
        name: 'user-account-settings-account-details',
        component: () => import('@/views/modules/user/user-account/user-account-settings/UserAccountSettingsDetails.vue'),
        meta: {
          pageTitle: '$UserSettings.$Account.$Details.TabTitle',

        },
      },
      {
        path: '/account-mobile/security',
        name: 'user-account-security',
        component: () => import('@/views/modules/user/user-account/user-account-security/UserAccountSecurity.vue'),
        meta: {
          pageTitle: '$General.Security',
        },
      },

      // =============================================================================
      // User billing page Routes
      // =============================================================================
      {
        path: '/billing',
        alias: [
          '/billing/payment-methods',
          '/billing/history',
        ],
        name: 'user-account-billing',
        component: () => import('@/views/modules/user/user-billing/UserAccountBilling.vue'),
        meta: {
          pageTitle: '$General.Billing',
          i18nPageTitle: 1,
        },
      },
      {
        path: '/billing-mobile/payment-methods',
        name: 'user-account-billing-payment-methods',
        component: () => import('@/views/modules/user/user-billing/UserAccountBillingPaymentMethods.vue'),
        meta: {
          pageTitle: '$UserSettings.$Billing.$PaymentMethods.TabTitle',

        },
      },
      {
        path: '/billing-mobile/history',
        name: 'user-account-billing-history',
        component: () => import('@/views/modules/user/user-billing/user-billing-history/UserAccountBillingHistory.vue'),
        props: {
          hideActionsColumn: true,
          hideTimestampsColumns: true,
        },
        meta: {
          pageTitle: '$UserSettings.$Billing.$History.TabTitle',
        },

      },
      // =============================================================================
      // User purchases page Routes
      // =============================================================================
      {
        path: '/purchases',
        alias: [
          '/purchases/my-purchases',
          '/purchases/my-purchases/my-prepaid-packages',
          '/purchases/my-purchases/my-dedicated-numbers',
          '/purchases/manage-top-ups/top-up-account',
          '/purchases/manage-top-ups/auto-top-up-account',
          '/purchases/dedicated-numbers',
        ],
        name: 'user-account-purchases',
        component: () => import('@/views/modules/user/user-purchases/UserAccountPurchases.vue'),
        meta: {
          pageTitle: '$General.Shop',
          i18nPageTitle: 1,
        },
      },
      {
        path: '/purchases-mobile/my-purchases',
        name: 'user-account-purchases-overview',
        component: () => import('@/views/modules/user/user-purchases/user-my-purchases/UserMyPurchasesTabs.vue'),
        meta: {
          pageTitle: '$UserSettings.$Shop.$ManageTopUps.$AutoTopUpAccount.TabTitle',

        },
      },
      {
        path: '/purchases-mobile/my-purchases/my-prepaid-packages',
        name: 'user-account-purchases-overview-my-prepaid-packages',
        component: () => import('@/views/modules/user/user-purchases/user-my-purchases/UserMyPurchasesPrepaidPackages.vue'),
        meta: {
          pageTitle: '$UserSettings.$Shop.$MyPurchases.$PrepaidPackages.TabTitle',

        },
      },
      {
        path: '/purchases-mobile/my-purchases/my-dedicated-numbers',
        name: 'user-account-purchases-overview-my-dedicated-numbers',
        component: () => import('@/views/modules/user/user-purchases/user-my-purchases/my-dedicated-numbers/UserMyPurchasesDedicatedNumbers.vue'),
        meta: {
          pageTitle: '$UserSettings.$Shop.$MyPurchases.$DedicatedNumbers.TabTitle',

        },
      },
      {
        path: '/purchases-mobile/manage-top-ups/top-up-account',
        name: 'user-account-purchases-top-up-account',
        component: () => import('@/views/modules/user/user-purchases/user-purchases-top-ups/UserAccountPurchasesTopUpAccount.vue'),
        meta: {
          pageTitle: '$UserSettings.$Shop.$ManageTopUps.$TopUpAccount.TabTitle',

        },
      },
      {
        path: '/purchases-mobile/manage-top-ups/auto-top-up-account',
        name: 'user-account-purchases-auto-top-up-account',
        component: () => import('@/views/modules/user/user-purchases/user-purchases-top-ups/UserAccountPurchasesAutoTopUpAccount.vue'),
        meta: {
          pageTitle: '$UserSettings.$Shop.$ManageTopUps.$AutoTopUpAccount.TabTitle',

        },
      },
      {
        path: '/purchases-mobile/dedicated-numbers',
        name: 'user-account-purchases-dedicated-numbers',
        component: () => import('@/views/modules/user/user-purchases/UserAccountPurchasesDedicatedNumbers.vue'),
        meta: {
          pageTitle: '$UserSettings.$Shop.$DedicatedNumbers.TabTitle',

        },
      },
      // =============================================================================
      // Pages Routes
      // =============================================================================
      {
        path: '/faq',
        name: 'page-faq',
        component: () => import('@/views/pages/Faq.vue'),
        meta: {
          pageTitle: '$General.FAQ',
        },
      },
      {
        path: '/documentation',
        name: 'documentation',
        component: () => import('@/views/pages/KnowledgeBase.vue'),
        meta: {
          pageTitle: '$General.Documentation',
        },
      },
      {
        path: '/documentation/:category',
        name: 'documentation-category',
        component: () => import('@/views/pages/KnowledgeBaseCategory.vue'),
        meta: {
          pageTitle: '$General.Documentation',
          parent: 'page-knowledge-base',
        },
      },
      {
        path: '/documentation/:category/:question',
        name: 'documentation-category-question',
        component: () => import('@/views/pages/KnowledgeBaseCategoryQuestion.vue'),
        meta: {
          pageTitle: '$General.Documentation',
          parent: 'page-knowledge-base',
        },
      },
      {
        path: '/pages/search',
        name: 'page-search',
        component: () => import('@/views/pages/Search.vue'),
        meta: {
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Pages' },
            { title: 'Search', active: true },
          ],
          pageTitle: 'Search',
        },
      },
      {
        path: '/pages/invoice',
        name: 'page-invoice',
        component: () => import('@/views/pages/Invoice.vue'),
        meta: {
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Pages' },
            { title: 'Invoice', active: true },
          ],
          pageTitle: 'Invoice',
        },
      },
    ],
  },
  // =============================================================================
  // FULL PAGE LAYOUTS
  // =============================================================================
  {
    path: '/',
    component: () => import('@/layouts/full-page/FullPage.vue'),
    children: [
      // =============================================================================
      // PAGES
      // =============================================================================
      {
        path: '/accept-terms-and-conditions',
        component: () => import('@/views/pages/auth/TheAcceptTermsAndConditions.vue'),
        name: 'accept-terms-and-conditions',
        meta: {
          requiresAuth: true,
          isTermsAndConditions: true,
        },
      },
      {
        path: '/callback',
        name: 'auth-callback',
        component: () => import('@/views/Callback.vue'),
        meta: {
        },
      },
      {
        path: '/login',
        alias: [
          '/login.html',
        ],
        name: 'login',
        component: () => import('@/views/pages/auth/TheAuth.vue'),
        props: { operation: 'Login' },
        meta: {
          requiresGuest: true,
        },
      },
      {
        path: '/log-in-second-step',
        name: 'login-second-step',
        component: () => import('@/views/pages/auth/TheAuthSecondStep.vue'),
      },
      {
        path: '/sign-up',
        alias: [
          '/signup.html',
        ],
        name: 'register',
        component: () => import('@/views/pages/auth/TheAuth.vue'),
        props: { operation: 'SignUp' },
        meta: {
          requiresGuest: true,
        },
      },
      {
        path: '/forgot-password',
        alias: [
          '/forgot-password.html',
        ],
        name: 'forgot-password',
        component: () => import('@/views/pages/auth/TheForgotPassword.vue'),
      },
      {
        path: '/reset-password/:tokenRecoverPassword',
        name: 'reset-password',
        component: () => import('@/views/pages/auth/TheResetPassword.vue'),
      },
      {
        path: '/invited-sign-up/:email/:securityToken',
        name: 'invited-sign-up',
        component: () => import('@/views/pages/auth/TheInviteRegister.vue'),
        meta: {
          requiresGuest: true,
        },
      },
      {
        path: '/not-authorized',
        name: 'not-authorized',
        component: () => import('@/views/pages/auth/TheNotAuthorized.vue'),
        meta: {
        },
      },
      {
        path: '/pages/lock-screen',
        name: 'page-lock-screen',
        component: () => import('@/views/pages/LockScreen.vue'),
        meta: {
        },
      },
      {
        path: '/pages/comingsoon',
        name: 'page-coming-soon',
        component: () => import('@/views/pages/ComingSoon.vue'),
        meta: {
        },
      },
      {
        path: '/contact/opt-out/:contactId/:optOutToken',
        name: 'contact-opt-out',
        component: () => import('@/views/pages/ContactOptOut.vue'),
      },
      {
        path: '/pages/error-404',
        name: 'page-error-404',
        component: () => import('@/views/pages/Error404.vue'),
      },
      {
        path: '/pages/error-500',
        name: 'page-error-500',
        component: () => import('@/views/pages/Error500.vue'),
        meta: {
        },
      },
      {
        path: '/pages/maintenance',
        name: 'page-maintenance',
        component: () => import('@/views/pages/Maintenance.vue'),
        meta: {
        },
      },
    ],
  },
  // Redirect to 404 page, if no match found
  {
    path: '*',
    redirect: '/pages/error-404',
  },
];

export default routes;
