import contactService from '@/api/modules/contact.service';

/**
 * Actions of the store contact module.
 *
 * Use the contact service to connect to the api. See {@link module:api/modules/contacts/contact}
 * @module store/contacts/contact/actions
 * @author Dilan Useche <dilan8810@gmail.com>
 * */
export default {
  /**
   * Get analytics lines data to chart
   *
   * @param {Object} rootState - vuex store root state
   * @param {Object} params - query params
   * @return {Promise.<Array.<Object> | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async fetchAnalyticsLines({ rootState }, params) {
    const resp = await contactService.getAnalyticsLines(params);
    return resp.data;
  },

  /**
   * Get analytics pie data to chart
   *
   * @param {Object} rootState - vuex store root state
   * @return {Promise.<Array.<Object> | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async fetchAnalyticsPie({ rootState }) {
    const resp = await contactService.getAnalyticsPie();
    return resp.data;
  },

  /**
   * Get contacts count with filters
   *
   * @param {Function} commit - vuex store commit function
   * @param {Object} payload - filters to queryFind
   * @returns {Promise.<Object | any>}
   */
  async fetchCountContactsFilters({ commit }, payload) {
    const resp = await contactService.getCountWithFilters(payload);
    return resp.data;
  },

  /**
   * Get all contacts count
   *
   * @param {Function} commit - vuex store commit function
   * @param {Object} params - query params
   * @return {Promise.<Array.<Object> | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async fetchCountContacts({ commit }, params) {
    const resp = await contactService.getCount(params);
    return resp.data;
  },

  /**
   * Get all contacts
   *
   * Use getAllByAudience contact service to fetch the contacts.
   * @param {Function} commit - vuex store commit function
   * @param {Object} params - query params
   * @return {Promise.<Array.<Object> | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async fetchAllContacts({ commit }, params) {
    const resp = await contactService.getAll(params);
    return resp.data;
  },

  /**
   * Get all contacts in the organization that the requesting user has access to in the trash
   *
   * Use getAllFromTrash contacts service to fetch the contacts.
   * @param {Function} commit - vuex store commit function
   * @param {Object} rootState - vuex store root state
   * @param {Object} params - query params
   * @return {Promise.<Array.<Object> | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async fetchAllContactsFromTrash({ commit }, params) {
    const resp = await contactService.getAllFromTrash(params);
    return resp.data;
  },

  /**
   * Get one contact by id
   *
   * Use getOne contact service to fetch the contacts
   * @param {Object} getters - vuex getters of recording module
   * @param {Object} state - vuex state of recording module
   * @param {string} contactId - id of contact to get
   * @return {Promise.<Object>} contact
   */
  // eslint-disable-next-line no-unused-vars
  async fetchContact({ getters }, contactId) {
    const resp = await contactService.getOne({ id: contactId });
    return resp.data;
  },

  /**
   * Create a new contact.
   *
   * Use create contact service to create the contact.
   * @param {Function} commit - vuex store commit function
   * @param {Object} contact - contact to save
   * @return {Promise.<Object | any>} contact created
   */
  // eslint-disable-next-line no-unused-vars
  async addContact({ commit }, contact) {
    const resp = await contactService.create(contact);
    return resp.data;
  },

  /**
   * Get the cost of check a number of contacts in DNC
   *
   * Use checkDncCost contact service to get the cost.
   * @param {Function} commit - vuex store commit function
   * @param {Object} rootState - vuex store root state
   * @param {Array.<Object> | null} data - contacts to check
   * @param {Object} filters - filters to search contacts to check
   * @return {Promise.<Object | any>} cost info
   */
  // eslint-disable-next-line no-unused-vars
  async checkDncCost({ rootState }, { data = null, filters = {} }) {
    const resp = await contactService.checkDncCost({
      data: data ? data.map((t) => t.id) : null,
      filters,
    });
    return resp.data;
  },


  /**
   * Import many contacts from file.
   *
   * Use importFromFile contact service to import the contacts.
   * See {@link module:api/modules/contacts/contact~importFromFile}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} rootState - vuex store root state
   * @param {Object[]} payloads - payloads to import contacts
   * @return {Promise.<Object | any>} import result
   */
  // eslint-disable-next-line no-unused-vars
  async importFromFile({ commit, rootState }, payloads) {
    const resp = await contactService.importFromFile(payloads);
    return resp.data;
  },

  /**
   * Export many contact from file.
   *
   * Use exportFile contact service to export the contacts.
   * See {@link module:api/modules/contacts/contact~exportFile}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} exportInfo - contacts export info
   * @return {Promise<string | any>} download link
   */
  // eslint-disable-next-line no-unused-vars
  async exportFile({ commit }, { exportInfo, trash = false }) {
    const resp = await contactService.exportFile(exportInfo, trash);
    return resp.data;
  },

  /**
   * Update and commit one contact.
   *
   * Use edit contact service to create the contact.
   * See {@link module:api/modules/contacts/contact~edit}.
   * @param {Function} commit - vuex store commit function
   * @param {string} id - id of contact to update
   * @param {Object} contact - contact to update
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async editContact({ commit }, { id, ...contact }) {
    const resp = await contactService.edit({
      id,
      payload: contact,
    });
    return resp.data;
  },

  /**
   * Restore one contact in the trash.
   *
   * Use restore contact service to restore the contact.
   * See {@link module:api/modules/contacts/contact~restore}.
   * @param {Function} commit - vuex store commit function
   * @param {string} contactId - id of contact to update
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async restoreContact({ commit }, contactId) {
    const resp = await contactService.restore(contactId);
    return resp.data;
  },

  /**
   * Delete one contact by id
   *
   * Use delete contact service to delete the contact.
   * See {@link module:api/modules/contacts/contact~delete}.
   * @param {Function} commit - vuex store commit function
   * @param {string} contactId - id of contact to delete
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async removeContact({ commit }, { contactId, trash = false }) {
    const resp = await contactService.delete(contactId, trash);
    return resp.data;
  },

  /**
   * Delete many contact groups by id
   *
   * Use deleteMany contact group service to delete the contact groups.
   * See {@link module:api/modules/contacts/contact-group~deleteMany}.
   * @param {Object} rootState - vuex store root state
   * @param {Object} getters - vuex store getters
   * @param {Array.<Object> | null} data - contacts to restore
   * @param {Object} filters - filters to search contacts to restore
   * @return {Promise.<any>}
   */
  // eslint-disable-next-line no-unused-vars
  async removeContacts({ rootState }, { data = null, filters = {}, trash = false }) {
    const resp = await contactService.deleteMany({
      data: data ? data.map((t) => t.id) : null,
      filters,
    },
    trash);
    return resp.data;
  },

  /**
   * Restore many contacts by id
   *
   * Use restoreMany contact service to restore the contact.
   * See {@link module:api/modules/contacts/contact~restoreMany}.
   * @param {Object} rootState - vuex store root state
   * @param {Array.<Object> | null} data - contacts to restore
   * @param {Object} filters - filters to search contacts to restore
   * @return {Promise.<any>}
   */
  // eslint-disable-next-line no-unused-vars
  async restoreContacts({ rootState }, { data = null, filters = {} }) {
    const resp = await contactService.restoreMany({
      data: data ? data.map((t) => t.id) : null,
      filters,
    });
    return resp.data;
  },
};
