import configApi from './config';

const axios = require('axios').default;

const grapePerksAppService = axios.create({
  baseURL: configApi.fakeDb ? '' : configApi.url,
  timeout: configApi.timeout,
  withCredentials: true,
});

const authRefreshUrl = `${configApi.baseRoutesUrls.auth}/refresh-tokens`;

if (configApi.token) {
  grapePerksAppService.defaults.headers.common.Authorization = `Bearer ${configApi.token}`;
}

grapePerksAppService.createResponseInterceptor = (vm, store) => {
  grapePerksAppService.interceptors.response.use(
    (response) => response,
    async (error) => {
      const status = error.response ? error.response.status : null;
      vm.$vs.loading.close();
      // eslint-disable-next-line no-param-reassign
      vm.preventAppLoading = true;
      // eslint-disable-next-line no-param-reassign

      if (status === 401 && !error.config.url.startsWith(configApi.baseRoutesUrls.auth)) {
        await store.dispatch('auth/refreshToken', {}, { root: true });
        // eslint-disable-next-line no-param-reassign
        error.config.headers.Authorization = `Bearer ${store.state.auth.token}`;
        return axios.request(error.config);
      }

      if (status === 401 && error.config.url === authRefreshUrl) {
        await store.dispatch('auth/logout', {}, { root: true });
        await vm.$router.push({ name: 'login' });

        vm.$showGeneralWarningNotification({
          title: vm.$t('$Errors.$Titles.SessionExpired'),
          text: vm.$t('$Errors.$Messages.SessionExpired'),
        });
      } else if (error.response.data
        && error.response.data.error
        && error.response.data.message
        && !error.response.data.skipNtf) {
        if (status >= 400 && status < 500) {
          vm.$showGeneralWarningNotification({
            title: error.response.data.error,
            text: error.response.data.message,
          });
        } else {
          vm.$showGeneralErrorNotification({
            title: error.response.data.error,
            text: error.response.data.message,
          });
        }
      }

      // eslint-disable-next-line no-param-reassign
      error.isGrapesendApiError = true;
      return Promise.reject(error);
    },
  );
};

export default grapePerksAppService;

/**
 * @typedef GrapePerksApiResponse
 * @type {object}
 * @property {number} statusCode - Http status code
 * @property {string} error - Description of request error
 * @property {Object} data - Response data
 * @property {Object} message - Description of request response
 */
