import inboundSmsService from '@/api/modules/inbound-sms.service';

/**
 * Actions of the store inbound-sms module.
 *
 * @module store/inbound-sms/actions
 * @author Dilan Useche <dilan8810@gmail.com>
 * */
export default {
  /**
   * Get all inbound sms in the organization that the requesting user has access to
   *
   * @param {Function} commit - vuex store commit function
   * @param {Object} params - query params
   * @return {Promise.<Object>}
   */
  // eslint-disable-next-line no-unused-vars
  async fetchAllInboundsSms({ commit }, params) {
    const resp = await inboundSmsService.getAll(params);
    return resp.data;
  },

  /**
   * Export many inbounds sms to file.
   *
   * @param {Function} commit - vuex store commit function
   * @param {Object} exportInfo - inbounds sms export info
   * @return {Promise<string | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async exportFile({ commit }, exportInfo) {
    const resp = await inboundSmsService.exportFile(exportInfo);
    return resp.data;
  },

  // eslint-disable-next-line no-unused-vars
  async removeInboundMessage({ commit }, inboundMessageId) {
    const resp = await inboundSmsService.delete(inboundMessageId);
    return resp.data;
  },

  // eslint-disable-next-line no-unused-vars
  async removeInboundMessages({ commit }, { data = null, filters = {} }) {
    const resp = await inboundSmsService.deleteMany({
      data: data ? data.map((t) => t.id) : null,
      filters,
    });

    return resp.data;
  },

};
