/**
 * Mutations of the store notification module.
 *
 * @module store/notification/mutations
 * @author Dilan Useche <dilan8810@gmail.com>
 * */
export default {
  /**
   * set the unseen collection of notifications
   *
   * @param {Object} state - vuex notification state
   * @param {Array.<Object>} notifications - notifications to set
   * @return {void}
   */
  SET_UNSEEN_COLLECTION(state, notifications) {
    state.unseenCollection = notifications;
  },

  /**
   * set the unseen count of notifications
   *
   * @param {Object} state - vuex notification state
   * @param {number} count - count to set
   * @return {void}
   */
  SET_UNSEEN_COUNT(state, count) {
    state.unseenCount = count;
  },

  /**
   * set the unseen notifications as seen
   *
   * @param {Object} state - vuex notification state
   * @return {void}
   */
  SET_AS_SEEN_COLLECTION(state) {
    state.unseenCollection = state.unseenCollection.map((ntf) => ({
      ...ntf,
      seen: true,
    }));
  },

  /**
   * add one unseen notification
   *
   * @param {Object} state - vuex notification state
   * @param {Object} notification - notifications to add
   * @return {void}
   */
  ADD_UNSEEN_NOTIFICATION(state, notification) {
    state.unseenCollection.unshift(notification);
    state.unseenCount += 1;
  },
};
