import Vue from 'vue';

import capitalizeFilter from '@/filters/capitalize.filter';
import camelizeFilter from '@/filters/camelize.filter';
import sentencecaseFilter from '@/filters/sentencecase.filter';
import uppercaseDashFilter from '@/filters/uppercase-dash.filter';
import titleFilter from '@/filters/title.filter';
import timeFilter from '@/filters/time.filter';
import dateFilter from '@/filters/date.filter';
import dateFormatFilter from '@/filters/date-format.filter';
import monthFilter from '@/filters/month.filter';
import cardExpFilter from '@/filters/card-exp.filter';
import dollarFilter from '@/filters/dollar.filter';
import phoneFilter from '@/filters/phone.filter';
import senderBusinessNameFilter from '@/filters/sender-business-name.filter';

Vue.filter('capitalize', capitalizeFilter);
Vue.filter('camelize', camelizeFilter);
Vue.filter('sentencecase', sentencecaseFilter);
Vue.filter('uppercase_dash', uppercaseDashFilter);
Vue.filter('title', titleFilter);
Vue.filter('time', timeFilter);
Vue.filter('date', dateFilter);
Vue.filter('date_format', dateFormatFilter);
Vue.filter('month', monthFilter);
Vue.filter('card_exp', cardExpFilter);
Vue.filter('dollar', dollarFilter);
Vue.filter('phone', phoneFilter);
Vue.filter('sender_business_name', senderBusinessNameFilter);

Vue.filter('lowercase', (value) => {
  if (!value) return '';
  const strValue = value.toString();
  return strValue.toLowerCase();
});
Vue.filter('uppercase', (value) => {
  if (!value) return '';
  const strValue = value.toString();
  return strValue.toUpperCase();
});
Vue.filter('truncate', (value, limit) => value.substring(0, limit));
Vue.filter('trim', (value) => value.trim());
Vue.filter('truncateEllipsis', (value, limit) => (value.length <= limit ? value : `${value.substring(0, limit)}...`));
Vue.filter('tailing', (value, tail) => value + tail);
Vue.filter('csv', (value) => value.join(', '));
Vue.filter('filter_tags', (value) => value.replace(/<\/?[^>]+(>|$)/g, ''));
Vue.filter('k_formatter', (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num));
Vue.filter('fixed_number', (value, precision = 2) => {
  return Number.isNaN(Number(value)) ? '0.00' : Number(value).toFixed(precision);
});
