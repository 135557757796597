/**
 * Store SMS Campaign module.
 *
 * @module store/campaigns/sms-campaign/module
 * @author Dilan Useche <dilan8810@gmail.com>
 * */

import state from './sms-campaign.state';
import mutations from './sms-campaign.mutations';
import actions from './sms-campaign.actions';

/**
 * export default
 *  @type {VuexModule}
 * */
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
