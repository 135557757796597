export default {
  setSelectedQuestionByIds({ state, commit, getters }, {
    categoryId,
    sectionId,
    questionId,
  }) {
    const question = getters.getQuestionByIds({
      categoryId,
      sectionId,
      questionId,
    });

    const category = state.data.find((c) => c.id === categoryId);

    if (category && question) {
      commit('SET_SELECTED_CATEGORY', category);
      commit('SET_SELECTED_QUESTION', question);
    }
  },
  setSelectedQuestionInSection({ commit }, { section, question }) {
    commit('SET_SELECTED_SECTION', section);
    commit('SET_SELECTED_QUESTION', question);
  },
  prevQuestion({ state, dispatch }) {
    const sectionIndex = state.selectedCategory.sections.findIndex(
      (section) => section.id === state.selectedSection.id,
    );
    const questionIndex = state.selectedSection.questions.findIndex(
      (question) => question.id === state.selectedQuestion.id,
    );

    let { selectedSection } = state;
    let { selectedQuestion } = state;

    const prevIndexQuestion = questionIndex - 1;
    let prevIndexSection = sectionIndex;

    if (prevIndexQuestion < 0) {
      prevIndexSection -= 1;

      if (prevIndexSection < 0) {
        selectedSection = state.selectedCategory.sections[
          state.selectedCategory.sections.length - 1];
      }

      selectedQuestion = selectedSection.questions[
        selectedSection.questions.length - 1];
    } else {
      selectedQuestion = selectedSection.questions[prevIndexQuestion];
    }

    debugger;
    dispatch('setSelectedQuestionInSection', {
      section: selectedSection,
      question: selectedQuestion,
    });
  },
  nextQuestion({ state, dispatch }) {
    const sectionIndex = state.selectedCategory.sections.findIndex(
      (section) => section.id === state.selectedSection.id,
    );
    const questionIndex = state.selectedSection.questions.findIndex(
      (question) => question.id === state.selectedQuestion.id,
    );

    let { selectedSection } = state;
    let { selectedQuestion } = state;

    const nextIndexQuestion = questionIndex + 1;
    let nextIndexSection = sectionIndex;

    if (nextIndexQuestion >= selectedSection.questions.length) {
      nextIndexSection += 1;

      if (nextIndexSection >= state.selectedCategory.sections.length) {
        [selectedSection] = state.selectedCategory.sections;
      }

      [selectedQuestion] = selectedSection.questions;
    } else {
      selectedQuestion = selectedSection.questions[nextIndexQuestion];
    }

    dispatch('setSelectedQuestionInSection', {
      section: selectedSection,
      question: selectedQuestion,
    });
  },
};
