import Vue from 'vue';

const avoidKeyEvent = (event) => {
  if (event.code === 'Tab') {
    event.preventDefault();
    event.stopPropagation();
  }
};

Vue.directive('modal-no-scroll-body', {
  componentUpdated(el, binding, vnode) {
    if (vnode && vnode.componentInstance && vnode.componentInstance.$props.active) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  },
});

Vue.directive('avoid-key-tab-event', {
  bind(el) {
    el.addEventListener('keydown', avoidKeyEvent, true);
  },
  unbind(el) {
    el.removeEventListener('keydown', avoidKeyEvent, true);
  },
});
