export default function (val, allYear = false) {
  const value = String(val);
  const parts = value.split('/');

  if (parts && Array.isArray(parts) && parts.length === 2) {
    const month = Number(parts[0]) < 10 ? `0${parts[0]}` : parts;
    const year = allYear ? parts[1] : parts[1].substr(2);

    return `${month}/${year}`;
  }

  return val;
}
