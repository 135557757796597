import grapePerksAppService from '../index';
import configApi from '../config';
/**
 * Auth service api
 * @exports api/auth
 * */
const authService = {};
const baseRoutesUrl = configApi.baseRoutesUrls.auth;

/**
 * Get access and refresh tokens from user credentials
 * @function signIn
 * @param {string} email - user email
 * @param {string} password - user password
 * @return {Promise.<GrapePerksApiResponse>} Tokens info and user payload
 */
authService.signIn = ({ email, password }) => grapePerksAppService.post(`${baseRoutesUrl}/sign-in`, {}, {
  auth: {
    username: email,
    password,
  },
}).then((resp) => resp.data);

/**
 * verify if access token is valid
 * @function verifyToken
 * @return {Promise.<GrapePerksApiResponse>} Tokens info and user payload
 */
authService.verifyToken = () => grapePerksAppService.get(`${baseRoutesUrl}/verify-token`)
  .then((resp) => resp.data);

/**
 * validate the email of user
 * @function validateEmailAccountByCode
 * @param {string} email - email of user
 * @param {string} securityCode - validation code
 * @return {Promise.<GrapePerksApiResponse>}
 */
authService.validateEmailAccountByCode = ({
  email,
  securityCode,
}) => grapePerksAppService.get(
  `${baseRoutesUrl}/validate-email-account/code/${email}/${securityCode}`,
).then((resp) => resp.data);

/**
 * resent email to validate the email of user
 * @function resendEmailValidateAccount
 * @param {string} email - email of user
 * @return {Promise.<GrapePerksApiResponse>}
 */
authService.resendEmailValidateAccount = (email) => grapePerksAppService.get(
  `${baseRoutesUrl}/resend-email-validate-account/${email}`,
).then((resp) => resp.data);

/**
 * Sign Up and login a new user
 * @function signUp
 * @param {Object} user - user info to save
 * @return {Promise.<GrapePerksApiResponse>} Tokens info and user payload
 */
authService.signUp = (user) => grapePerksAppService.post(`${baseRoutesUrl}/sign-up`, user)
  .then((resp) => resp.data);

/**
 * Sign in from the second step
 * @function signInSecondStep
 *
 * @param {String} code - Code two factor authentication of the user
 * @return {Promise.<GrapePerksApiResponse>} Tokens info and user payload
 */

authService.signInSecondStep = ({ code, tokenSecondStep }) => grapePerksAppService.post(
  `${baseRoutesUrl}/second-step-login/${code}`,
  {},
  { headers: { Authorization: `Bearer ${tokenSecondStep}` } },
).then((resp) => resp.data);

/**
 * Sign Up and login a new invited user
 * @function invitedSignUp
 * @param {string} email - email to complete register
 * @param {string} securityToken - security token to complete register
 * @param {Object} user - user info to save
 * @return {Promise.<GrapePerksApiResponse>} Tokens info and user payload
 */
authService.invitedSignUp = ({ email, securityToken, user }) => grapePerksAppService.post(`${baseRoutesUrl}/invited-sign-up/${email}/${securityToken}`, user)
  .then((resp) => resp.data);

/**
 * Request an recover email to change the user password
 * @function recoverPassword
 * @param {string} email - email address to sending
 * @return {Promise.<GrapePerksApiResponse>} void
 */
authService.recoverPassword = (email) => grapePerksAppService.post(`${baseRoutesUrl}/forgot-password/${email}`, {})
  .then((resp) => resp.data);

/**
 * Change user password by security token
 * @function changePassword
 * @param {string} jwtTokenRecoverPassword - security JWT token to make operation
 * @param {string} password - new password
 * @return {Promise.<GrapePerksApiResponse>} User updated
 */
authService.changePassword = ({ jwtTokenRecoverPassword, password }) => grapePerksAppService.post(`${baseRoutesUrl}/forgot-password/confirmation/${jwtTokenRecoverPassword}`, { password })
  .then((resp) => resp.data);

/**
 * Get access and refresh tokens from refresh token
 * @function refreshTokens
 * @return {Promise.<GrapePerksApiResponse>} Tokens info
 */
authService.refreshTokens = () => grapePerksAppService.post(`${baseRoutesUrl}/refresh-tokens`, {}, { headers: { Authorization: configApi.refreshToken ? `Bearer ${configApi.refreshToken}` : '' } })
  .then((resp) => resp.data);

/**
 * Force access token expiration and logout app
 * @function logout
 * @return {Promise.<GrapePerksApiResponse>} void
 */
authService.logout = () => grapePerksAppService.post(`${baseRoutesUrl}/log-out`)
  .then((resp) => resp.data);

export default authService;
