export default function (value, decimals = 2) {
  if (value === '') {
    return value;
  }

  const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: decimals,
  });

  return formatter.format(value);
}
