/**
 * Store auth module.
 *
 * @module store/auth/module
 * @author Dilan Useche <dilan8810@gmail.com>
 * */

import { state } from './auth.state';
import mutations from './auth.mutations';
import actions from './auth.actions';
import getters from './auth.getters';

/**
 * export default
 *  @type {VuexModule}
 * */
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
