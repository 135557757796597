export default function (value) {
  if (!value) return '';

  value = value.toString();
  const arr = value.split(' ');
  const capitalizedArray = [];

  arr.forEach((word, index) => {
    const capitalized = index > 0
      ? word.charAt(0).toUpperCase() + word.slice(1)
      : word;
    capitalizedArray.push(capitalized);
  });

  return capitalizedArray.join(' ');
}
