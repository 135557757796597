import grapePerksAppService from '@/api';
import configApi from '@/api/config';
import ResourceService from '@/api/resource.service';

/**
 * contact service api
 * @class ContactService
 */
class ContactService extends ResourceService {
  constructor() {
    super({ baseUrl: configApi.baseRoutesUrls.contacts });
  }

  /**
   * Get contact opt-out info
   * @function optOutInfo
   * @param {string} contactId - id of contact to get company name
   * @param {string} optOutToken - optOut token of contact
   * @return {Promise.<GrapePerksApiResponse | any>}
   */
  optOutInfo = ({ contactId, optOutToken }) => grapePerksAppService.get(
    `${this.baseUrl}/opt-out-info/${contactId}/${optOutToken}`,
  ).then((resp) => resp.data);

  /**
   * Get analytics audience contacts for lines chart
   * @function getAnalyticsLines
   * @param {Object} params - query params
   * @return {Promise.<GrapePerksApiResponse | any>}
   */
  getAnalyticsLines = (params) => grapePerksAppService.get(
    '/history-contacts/analytics-lines', { params },
  ).then((resp) => resp.data);

  /**
   * Get analytics audience contacts for pie chart
   * @function getAnalyticsPie
   * @return {Promise.<GrapePerksApiResponse | any>}
   */
  getAnalyticsPie = () => grapePerksAppService.get(
    `${this.baseUrl}/analytics-pie/`,
  ).then((resp) => resp.data);

  /**
   * Retrieve the cost of check a number of contacts in DNC
   * @function checkDnc
   * @param {Object} dataRequest - data of request to checks cost
   * @return {Promise.<GrapePerksApiResponse | any>} DNC Result
   */
  checkDncCost = (dataRequest) => grapePerksAppService.post(
    `${this.baseUrl}/dnc-cost`, dataRequest,
  ).then((resp) => resp.data);


  /**
   * Opt out from one service
   * @function optOut
   * @param {string} contactId - id of contact to make opt out
   * @param {string} optOutToken - optOut token of contact
   * @return {Promise.<GrapePerksApiResponse | any>}
   */
  optOut = ({ contactId, optOutToken }) => grapePerksAppService.put(
    `${this.baseUrl}/opt-out/${contactId}/${optOutToken}`, {},
  ).then((resp) => resp.data);
}

const singletonInstance = new ContactService();

/**
 * contact service api instance
 * @exports api/modules/contacts/contact
 * */
export default singletonInstance;
