import BaseService from '@/api/base.service';
import configApi from '@/api/config';

/**
 * inbound sms service api
 * @class InboundSmsService
 */
class InboundSmsService extends BaseService {
  constructor() {
    super({ baseUrl: configApi.baseRoutesUrls.inboundSms });
  }
}

const singletonInstance = new InboundSmsService();

/**
 * inbound sms service api instance
 * @exports api/modules/inbound-sms
 * */
export default singletonInstance;
