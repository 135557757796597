import segmentService from '@/api/modules/segment.service';

/**
 * Actions of the store segment module.
 *
 * Use the segment service to connect to the api.
 * See {@link module:api/modules/audiences/segment}
 * @module store/audiences/segment/actions
 * @author Dilan Useche <dilan8810@gmail.com>
 * */
export default {
  /**
   * Get all segments in the organization that the requesting user has access to
   *
   * Use getAll segment service to fetch the segments.
   * See {@link module:api/modules/audiences/segment~getAll}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} params - query params
   * @return {Promise.<Array.<Object> | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async fetchAllSegments({ commit }, params) {
    const resp = await segmentService.getAll(params);
    return resp.data;
  },

  /**
   * Get one segment by id
   *
   * Use getOne segment service to fetch the segment
   * See {@link module:api/modules/audiences/segment~getOne}.
   * @param {Object} getters - vuex getters of segment module
   * @param {Object} state - vuex state of segment module
   * @param {string} segmentId - id of segment to get
   * @return {Promise.<Object>} segment
   */
  // eslint-disable-next-line no-unused-vars
  async fetchSegment({ getters }, segmentId) {
    const resp = await segmentService.getOne({ id: segmentId });
    return resp.data;
  },

  /**
   * Create a new segment.
   *
   * Use create segment service to create the segment.
   * See {@link module:api/modules/audiences/segment~create}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} payload - segment to create
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async addSegment({ commit }, payload) {
    const resp = await segmentService.create(payload);
    return resp.data;
  },

  /**
   * Export many segments ids from file.
   *
   * Use exportFile segment service to export the segments ids.
   * See {@link module:api/modules/audiences/segment~exportFile}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} exportInfo - segment export info
   * @return {Promise<string | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async exportFile({ commit }, exportInfo) {
    const resp = await segmentService.exportFile(exportInfo);
    return resp.data;
  },

  /**
   * Update and commit one segment.
   *
   * Use edit segment service to update the segment.
   * See {@link module:api/modules/audiences/segment~edit}.
   * @param {Function} commit - vuex store commit function
   * @param {string} id - id of segment to update
   * @param {Object} segment - segment to update
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async editSegment({ commit }, { id, ...segment }) {
    const resp = await segmentService.edit({
      id,
      payload: segment,
    });
    return resp.data;
  },

  /**
   * Delete one segment by id
   *
   * Use delete segment service to delete the segment.
   * See {@link module:api/modules/audiences/segment~delete}.
   * @param {Function} commit - vuex store commit function
   * @param {string} segmentId - id of segment to delete
   * @return {Promise.<Object | any>}
   */
  // eslint-disable-next-line no-unused-vars
  async removeSegment({ commit }, segmentId) {
    const resp = await segmentService.delete(segmentId);
    return resp.data;
  },

  /**
   * Delete many segments ids by id
   *
   * Use deleteMany segment service to delete the segments ids.
   * See {@link module:api/modules/audiences/segment~deleteMany}.
   * @param {Function} commit - vuex store commit function
   * @param {Array.<Object> | null} data - segments ids to delete
   * @param {Object} filters - filters to search segments ids to delete
   * @return {Promise.<any>}
   */
  // eslint-disable-next-line no-unused-vars
  async removeSegments({ commit }, { data = null, filters = {} }) {
    const resp = await segmentService.deleteMany({
      data: data ? data.map((t) => t.id) : null,
      filters,
    });

    return resp.data;
  },
};
