export default function VsAlertPlugin(Vue) {
  Vue.mixin({
    methods: {
      $showAlertActionDialog({
        title,
        text,
        acceptText,
        accept,
      }) {
        this.$showAlertDialog({
          title,
          text,
          color: 'primary',
          acceptText,
          accept,
          buttonAccept: 'accept-fill',
        });
      },
      $showAlertDeleteDialog({
        title,
        text,
        acceptText,
        accept,
      }) {
        this.$showAlertDialog({
          title,
          text,
          color: 'danger',
          acceptText,
          accept,
          buttonAccept: 'accept-fill',
        });
      },
      $showAlertWarningDialog({
        title,
        text,
        acceptText,
        accept,
      }) {
        this.$showAlertDialog({
          title,
          text,
          color: 'warning',
          acceptText,
          accept,
          buttonAccept: 'accept-fill',
        });
      },
      $showAlertDialog({
        title,
        text,
        color = '',
        acceptText,
        accept,
      }) {
        this.$vs.dialog({
          type: 'alert',
          color,
          title,
          text,
          acceptText,
          accept,
          buttonAccept: 'accept-fill',
        });
      },
    },
  });
}
