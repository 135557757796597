/**
 * Store inbound-sms module.
 *
 * @module store/inbound-sms/module
 * @author Dilan Useche <dilan8810@gmail.com>
 * */

import state from './inbound-sms.state';
import mutations from './inbound-sms.mutations';
import actions from './inbound-sms.actions';
import getters from './inbound-sms.getters';

/**
 * export default
 *  @type {VuexModule}
 * */
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
