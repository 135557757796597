import BaseService from '@/api/base.service';
import configApi from '@/api/config';
import grapePerksAppService from '@/api';

/**
 * AppServiceService service api
 * @class AppServiceService
 */
class AppServiceService extends BaseService {
  constructor() {
    super({ baseUrl: configApi.baseRoutesUrls.appServices });
  }

  /**
   * Get one App Service by key
   * @function getAppServiceByKey
   * @return {Promise.<GrapePerksApiResponse | any>} app service
   */
  getAppServiceByKey = (key) => grapePerksAppService.get(
    `${this.baseUrl}/by-key/${key}`,
  ).then((resp) => resp.data);

  /**
   * Get the cost of provider service
   * @function getOne
   * @param {string} serviceKey - key of the service to get provider cost
   * @return {Promise.<GrapePerksApiResponse | any>}
   */
  getProviderCost = (serviceKey) => grapePerksAppService.get(
    `${this.baseUrl}/get-provider-cost?key=${serviceKey}`,
  ).then((resp) => resp.data);
}

const singletonInstance = new AppServiceService();

/**
 * app service service api instance
 * @exports api/modules/app-service
 * */
export default singletonInstance;
