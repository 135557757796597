import notificationService from '@/api/modules/notification.service';

/**
 * Actions of the store notification module.
 *
 * Use the notification service to connect to the api. See {@link module:api/modules/notification}
 * @module store/notification/actions
 * @author Dilan Useche <dilan8810@gmail.com>
 * */
export default {
  /**
   * Get all notifications in the organization that the requesting user has access to
   *
   * Use getAll notification service to fetch the notifications.
   * See {@link module:api/modules/notification~getAll}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} params - query params
   * @return {Promise.<Object>}
   */
  // eslint-disable-next-line no-unused-vars
  async fetchAllNotifications({ commit }, params) {
    const resp = await notificationService.getAll(params);
    return resp.data;
  },

  /**
   * Get all notifications by day
   *
   * @param {Function} commit - vuex store commit function
   * @param {Object} params - query params
   * @return {Promise.<Object>}
   */
  // eslint-disable-next-line no-unused-vars
  async fetchAllNotificationsByDay({ commit }, params) {
    const resp = await notificationService.getAllByDay(params);
    return resp.data;
  },

  /**
   * Get all unseen notifications in the organization that the requesting user has access to
   *
   * Use getUnseen notification service to fetch the notifications.
   * See {@link module:api/modules/notification~getUnseen}.
   * @param {Function} commit - vuex store commit function
   * @return {Promise.<void>}
   */
  async fetchUnseenNotifications({ commit }) {
    const resp = await notificationService.getUnseen();
    commit('SET_UNSEEN_COUNT', resp.data.count);
    commit('SET_UNSEEN_COLLECTION', resp.data.data);
  },

  /**
   * Update notifications as viewed
   *
   * Use updateAsViewed notification service to update notifications.
   * See {@link module:api/modules/notification~getAll}.
   * @param {Function} commit - vuex store commit function
   * @param {Object} state - vuex store state
   * @return {Promise.<void>}
   */
  async updateNotificationsAsViewed({ commit, state }) {
    const ids = state.unseenCollection.map((n) => n.id);
    await notificationService.updateAsViewed(ids);
    commit('SET_UNSEEN_COUNT', 0);
    commit('SET_AS_SEEN_COLLECTION');
  },
};
