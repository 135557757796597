import BaseService from '@/api/base.service';
import configApi from '@/api/config';
import grapePerksAppService from '@/api';

/**
 * Tenant service api
 * @class TenantService
 */
class TenantService extends BaseService {
  constructor() {
    super({ baseUrl: configApi.baseRoutesUrls.tenants });
  }

  /**
   * Get the tenant of user
   * @function getTenantFromUser
   * @return {Promise.<GrapePerksApiResponse>} tenant
   */
  getTenantFromUser = () => grapePerksAppService.get(
    `${this.baseUrl}/tenant-from-user`,
  ).then((resp) => resp.data);

  /**
   * Get the flags of user
   * @function getFlags
   * @return {Promise.<GrapePerksApiResponse>} flags of user
   */
  getFlags = () => grapePerksAppService.get(
    `${this.baseUrl}/flags`,
  ).then((resp) => resp.data);

  /**
   * Get balance information of the auth user
   * @function getBalanceInformation
   * @return {Promise.<GrapePerksApiResponse>}
   */
  getBalanceInformation = () => grapePerksAppService.get(
    `${this.baseUrl}/balance-information`,
  ).then((resp) => resp.data);

  /**
   * Get billing information of the auth user
   * @function getBillingInformation
   * @return {Promise.<GrapePerksApiResponse>}
   */
  getBillingInformation = () => grapePerksAppService.get(
    `${this.baseUrl}/billing-information`,
  ).then((resp) => resp.data);

  /**
   * Get billing history transactions
   * @function getBillingHistory
   * @param {Object} params - query params
   * @return {Promise.<GrapePerksApiResponse>}
   */
  getBillingHistory = (params) => grapePerksAppService.get(
    `${this.baseUrl}/billing-history`, { params },
  ).then((resp) => resp.data);

  /**
   * Get payment methods of tenant
   * @function getPaymentMethods
   * @return {Promise.<GrapePerksApiResponse>} list of payment methods
   */
  getPaymentMethods = () => grapePerksAppService.get(
    `${this.baseUrl}/payment-methods`,
  ).then((resp) => resp.data);

  /**
   * Get app service items of tenant
   * @function getAppServiceItems
   * @return {Promise.<GrapePerksApiResponse>} list of app service items
   */
  getAppServiceItems = () => grapePerksAppService.get(
    `${this.baseUrl}/app-service-items`,
  ).then((resp) => resp.data);

  /**
   * Get tenant account settings details
   * @function getAccountSettingsDetails
   * @return {Promise.<GrapePerksApiResponse>} account settings
   */
  getAccountSettingsDetails = () => grapePerksAppService.get(
    `${this.baseUrl}/account-settings/details`,
  ).then((resp) => resp.data);

  /**
   * Get tenant account settings reviews
   * @function getAccountSettingsReviews
   * @return {Promise.<GrapePerksApiResponse>} account settings reviews
   */
  getAccountSettingsReviews = () => grapePerksAppService.get(
    `${this.baseUrl}/account-settings/reviews`,
  ).then((resp) => resp.data);

  /**
   * Get tenant account settings branding
   * @function getAccountSettingsBranding
   * @return {Promise.<GrapePerksApiResponse>} account settings branding
   */
  getAccountSettingsBranding = () => grapePerksAppService.get(
    `${this.baseUrl}/account-settings/branding`,
  ).then((resp) => resp.data);

  /**
   * Get config auto top up account
   * @function getConfigAutoTopUpAccount
   * @return {Promise.<GrapePerksApiResponse>} account auto top up account information
   */

  getConfigAutoTopUpAccount = () => grapePerksAppService.get(
    `${this.baseUrl}/auto-top-up-account`,
  ).then((resp) => resp.data);

  /**
   * make a payment intent to top up account
   * @function paymentTopUpAccount
   * @param {string} payload - payload to payment
   * @return {Promise.<GrapePerksApiResponse>}
   */
  paymentTopUpAccount = (payload) => grapePerksAppService.post(
    `${this.baseUrl}/top-up-account/payment-intent`, payload,
  ).then((resp) => resp.data);

  /**
   * add a new credit card to user
   * @function addCreditCard
   * @param {string} payload - payload of credit card
   * @return {Promise.<GrapePerksApiResponse>}
   */
  addCreditCard = (payload) => grapePerksAppService.post(
    `${this.baseUrl}/add-credit-card`, payload,
  ).then((resp) => resp.data);

  /**
   * Update tenant account settings details
   * @function updateAccountSettingsDetails
   * @param {Object} payload - details
   * @return {Promise.<GrapePerksApiResponse>}
   */
  updateAccountSettingsDetails = (payload) => grapePerksAppService.put(
    `${this.baseUrl}/account-settings/details`, payload,
  ).then((resp) => resp.data);

  /**
   * Update tenant account settings details
   * @function updateAccountSettingsDetails
   * @param {Object} payload - details
   * @return {Promise.<GrapePerksApiResponse>}
   */
  updateAccountSettingsReviews = (payload) => grapePerksAppService.put(
    `${this.baseUrl}/account-settings/reviews`, payload,
  ).then((resp) => resp.data);

  /**
   * Update tenant branding logo
   * @function updateBrandingLogo
   * @param {string} url - logo url
   * @return {Promise.<GrapePerksApiResponse>}
   */
  updateBrandingLogo = (url) => grapePerksAppService.put(
    `${this.baseUrl}/account-settings/branding/logo`, { logo: url },
  ).then((resp) => resp.data);

  /**
   * Update tenant branding colours
   * @function updateBrandingColours
   * @param {Object} payload - colours
   * @return {Promise.<GrapePerksApiResponse>}
   */
  updateBrandingColours = (payload) => grapePerksAppService.put(
    `${this.baseUrl}/account-settings/branding/colors`, payload,
  ).then((resp) => resp.data);

  /**
   * Update the config of the auto top up account
   * @function updateAutoTopUpAccountConfig
   * @param payload
   * @returns {Promise.<GrapePerksApiResponse>}
   */

  updateAutoTopUpAccountConfig = (payload) => grapePerksAppService.put(
    `${this.baseUrl}/auto-top-up-account`, payload,
  ).then((resp) => resp.data);

  /**
   * Update user flags
   * @function updateFlags
   * @param {Object} payload - flags payload
   * @return {Promise.<GrapePerksApiResponse>}
   */
  updateFlags = (payload) => grapePerksAppService.put(
    `${this.baseUrl}/flags`, payload,
  ).then((resp) => resp.data);

  /**
   * Update default payment method for an app service item in the tenant
   * @function updateAppServiceItemDefaultPaymentMethod
   * @param {string} callerId - payment method id to set as default
   * @param {string} paymentMethodId - payment method id to set as default
   * @return {Promise.<GrapePerksApiResponse>}
   */
  updateAppServiceItemDefaultPaymentMethod = (callerId, paymentMethodId) => grapePerksAppService.put(
    `${this.baseUrl}/app-service-item/default-payment-method/${callerId}`,
    {
      paymentMethod: paymentMethodId,
    },
  ).then((resp) => resp.data);

  /**
   * Update user billing information
   * @function updateDefaultPaymentMethod
   * @param {string} paymentMethodId - payment method id to set as default
   * @return {Promise.<GrapePerksApiResponse>} list of payment methods
   */
  updateDefaultPaymentMethod = (paymentMethodId) => grapePerksAppService.put(
    `${this.baseUrl}/default-payment-method/${paymentMethodId}`,
  ).then((resp) => resp.data);

  /**
   * Cancel the monthly package of auth user
   * @function cancelMonthlyPackage
   * @return {Promise.<GrapePerksApiResponse>}
   */
  cancelMonthlyPackage = () => grapePerksAppService.put(
    `${this.baseUrl}/cancel-monthly-package/`,
  ).then((resp) => resp.data);

  /**
   * Pay a dedicated number
   * @function payDedicatedNumber
   * @param {object} payload - payload to pay dedicated number
   * @return {Promise.<GrapePerksApiResponse>}
   */
  payDedicatedNumber = (payload) => grapePerksAppService.put(
    `${this.baseUrl}/pay-dedicated-number/`, payload,
  ).then((resp) => resp.data);

  /**
   * remove payment method from user
   * @function removePaymentMethod
   * @return {Promise.<GrapePerksApiResponse>} list of payment methods
   */
  removePaymentMethod = (paymentMethodId) => grapePerksAppService.delete(
    `${this.baseUrl}/payment-method/${paymentMethodId}`,
  ).then((resp) => resp.data);

  /**
   * cancel subscription belongs to the dedicatedNumber
   * @function cancelDedicatedNumberSubscription
   * @return {Promise.<GrapePerksApiResponse>}
   */
  cancelOrDontCancelDedicatedNumberSubscription = (idCaller, toCancelNewStatus) => grapePerksAppService.put(
    `${this.baseUrl}/cancel-or-dont-cancel-dedicated-number-subscription/${idCaller}/${toCancelNewStatus}`,
  )
}

const singletonInstance = new TenantService();

/**
 * tenant service api instance
 * @exports api/modules/tenant
 * */
export default singletonInstance;
